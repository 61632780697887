/* Login Page  */
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  outline: none;
}
html{
  background-color: #f2f2f2;
}
body {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;
  overflow-x: hidden !important;
}
.swal2-toast-shown .swal2-container{
  /* width: auto !important; */
}

.btn-default:hover {
  color: #fff !important;
  background-color: #02857a !important;
  border-color: #02857a !important;
}

/* classroom zoom on hover */
.zoomIn:hover {
  box-shadow: 0 2px 6px #aaa;
  cursor: pointer;
  transform: scale(1.01);
  transition: all 0.2s linear 0s;
}
.login {
  background: transparent url(../imgs/bg-lotus.jpg) center center !important;
  background-size: cover !important;
  overflow: hidden !important;
  height: 100%;
  min-height: 100vh;
}

.login-demo {
  position: absolute;
  top: 12px;
  right: 60px;
}

.login .dropdown-language {
  position: absolute;
  right: 10px;
  top: 10px;
}

/* Register in login section  */

.login-card div.front,
.login-card div.back {
  display: block;
  backface-visibility: hidden;
}

.inner-block.small {
  width: 100%;
  height: auto;
  max-height: 100%;
  max-width: 100%;
  background: transparent;
  margin: 0;
}

.reg {
  padding: 20% 22%;
}

.reg h1 {
  font-size: 22px;
  color: #fff;
  text-align: center;
  display: block;
  font-weight: 400;
  margin-bottom: 40px;
}

.block-right .form-block .acd-btn,
.block-right .form-block .couns-btn {
  height: 45px;
  border-radius: 5px;
  background: transparent;
  border: 1px solid #fff;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 30px;
}

.back .flip-link {
  color: #fff;
  position: static;
  font-size: 16px;
  text-align: right;
}

/* Modal in new page  */
.iamStudentbody {
  padding: 10px;
}

.registeration-form {
  max-width: 680px;
  width: 100%;
  padding: 15px;
  margin: auto;
  background: #fff;
  border-radius: 5px;
  overflow: hidden;
}

.or_text {
  text-align: center;
  border-bottom: 1px solid #eee;
  margin: 0 30px 20px;
  display: block;
  line-height: 0;
  padding: 0;
}

.or_text span {
  width: 25px;
  height: 25px;
  background: #ccc;
  border-radius: 100%;
  color: #fff;
  display: inline-block;
  font-size: 12px;
  line-height: 26px;
  text-align: center;
  font-weight: bold;
  margin-bottom: -12px;
}

#nextdiv {
  float: none;
  display: inline;
}

.btn-cancel {
  margin-top: 10px;
  background: transparent;
  color: #b4b4b4;
}

.registeration-form h2 {
  padding: 0px 16px 0px;
  margin-bottom: 26px;
  font-size: 20px;
  color: #3177c9;
  font-weight: 400;
}

.registeration-form form .control-label {
  font-size: 16px;
  font-weight: 400;
  color: #999;
}

#loginForm h1 {
  font-size: 24px;
  text-align: center;
  display: block;
  font-weight: 400;
  margin-bottom: 40px;
}

#loginForm {
  display: none;
  position: relative;
  z-index: 999 !important;
}

/* register on academics  */

.block-right .reg-option {
  padding: 15% 0;
  margin-bottom: 30px;
}

.block-right .reg-option ul.register {
  width: 60%;
  margin: auto;
}

.block-right .reg-option ul.register li {
  float: left;
  width: 100%;
  list-style: none;
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  color: #3177c9;
  padding: 10px;
  border: 1px solid #3177c9;
  margin: 10px;
  border-radius: 5px;
  height: 45px;
}

.reg-option h1 {
  font-size: 24px;
  text-align: center;
}

.counseller .link,
.counselling .link {
  position: absolute;
  color: #337ab7;
  top: 20px;
  display: block;
  font-size: 16px;
  text-align: right;
  right: 30px;
  text-decoration: none;
  border: none;
  outline: none;
}

.backtoLogin {
  display: inline-block;
  clear: both;
  width: 82%;
  margin: 10px auto;
  font-size: 16px;
  text-align: right;
}

.login-card.flipped {
  transform: rotateY(180deg) !important;
}

.login-card .back {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 999 !important;
  background: rgb(142, 68, 173);
  transform: rotateY(180deg);
}

.FirstForm {
  display: inline-block;
}

#cars {
  color: black !important;
}

.topMenu {
  display: none;
  left: -128px;
}
ul.dropdown-menu.topMenu li {
  padding: 6px 10px;
  cursor: pointer;
}
ul.dropdown-menu.topMenu li:first-child {
  border-bottom: 1px solid #cbc8c8;
}
ul.dropdown-menu.topMenu li:hover {
  background: #ebe6e6;
}

.block-left .form-block .form-control {
  height: 45px;
  border-radius: 5px;
  box-shadow: none;
  padding: 0px 20px;
  font-size: 16px;
}

.dropdown-language .question-options.language {
  font-size: 10px;
  color: #fff;
}



.dropdown-language a,
.dropdown-language a:hover,
.dropdown-language a:focus,
.dropdown-language a:active {
  text-decoration: none;
}

.dropdown-language .language {
  font-size: 14px;
  line-height: 28px;
  padding-right: 5px;
}

.text-center {
  text-align: center;
}

.logo img {
  width: 150px;
  display: inline-block;
  padding: 2% 0 3%;
}

.block {
  display: block;
  max-width: 1120px;
  width: 70%;
  height: 83vh;
  margin: auto;
}

.login-bg-container {
  margin: auto;
  transition: all 0.3s linear;
  max-width: 560px;
  perspective: 800px;
  /* background-color: #fff; */
}
.login-card .front {
  background: rgba(256, 256, 256, 1);
}

.login-card {
  transition: transform 1s;
  transform-style: preserve-3d;
}

.block-left .form-block {
  padding: 15% 20%;
}

.Userh1 {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px !important;
  margin-bottom: 40px;
}

.block-left .form-block .login-btn {
  height: 45px;
  border-radius: 5px;
  background: #8e44ad;
  border: 1px solid #8e44ad;
  color: #fff;
  font-size: 20px;
  font-weight: 400;
  margin-top: 24px;
}

.forgot:hover {
  text-decoration: underline;
}

#fliptoBack:hover {
  text-decoration: underline;
}

.block .form-block h1 {
  text-align: center;
  display: block;
  font-weight: 400;
  margin-bottom: 40px;
}

.flip-link {
  text-align: center;
  display: block;
  font-size: 16px;
  clear: both;
}

.help-block {
  color: #a94442;
}

.login-forgot-btn {
  float: right;
}

/* Css for Dashboard Page */
.nocls-add {
  width: 100%;
}

tspan {
  background: #f2f2f2;
  top: 0;
  position: relative;
  height: 100%;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  line-height: 1.42857143;
  color: #333;
}

a {
  color: #266eaf;
  text-decoration: none;
}

.dashboard-header {
  background: #fff;
  height: 70px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;
}

.boot-row {
  margin: 0 -15px;
}

.logo-area {
  width: 130px;
  padding: 0;
  float: left;
}

.logo1 {
  background: rgba(142, 68, 173, 0.9) !important;
  line-height: 60px;
  min-height: 70px;
  text-align: center;
  padding: 5px;
}

.logo1 img {
  display: inline-block;
}

img.img-responsive {
  vertical-align: middle;
}

.header-area {
  width: calc(100% - 165px);
  padding: 0 -3px;
  float: left;
  margin-left: 10px;
}

.header-area h2 {
  margin: 0;
  font-size: 22px;
  font-weight: 400;
  margin-top: 20px;
  color: #3a3f41;
}

.nav-right {
  padding-top: 24px;
}

.logout {
  border-left: 1px solid #dadada;
  float: right;
  padding-left: 13px;
}

.user_name {
  color: #666;
  display: block;
  font-size: 14px;
  margin-right: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  float: right;
  text-transform: capitalize;
}

/* For side-nav fixed  */

.side-nav.fixed.fixed {
  position: fixed;
  z-index: 1036;
}

.side-nav.fixed {
  margin-top: 70px;
  height: 100%;
  width: 130px;
}

.side-nav {
  background-color: #454562;
}

ol,
ul {
  margin-top: 0;
  margin-bottom: 10px;
}

.side-nav li {
  width: 100%;
  text-align: center;
  display: table;
  position: relative;
}

.side-nav li.active a,
.side-nav li:hover a {
  background: #454562;
}

.side-nav li a {
  vertical-align: middle;
  display: table-cell;
  color: #fff;
  text-decoration: none;
  text-align: center;
  padding: 6px 0px;
}

.side-nav li a i {
  font-size: 30px;
}

.side-nav li span {
  display: inline-block;
  /* padding-top: 7px; */
  width: 100%;
  font-size: 12px;
}

.side-nav .badge {
  top: 7px;
  position: absolute;
  left: 84px;
  background: #d44242;
}

.badge {
  display: inline-block;
  min-width: 10px;
  padding: 3px 7px;
  font-size: 12px;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 10px;
  background-color: #767676;
}
/* #sidebar-menu {
  height: 100%;
  overflow: auto;
  max-height: 550px;
  min-height: 550px;
} */
#sidebar-menu li.active a .arrow-point {
  display: block;
}

.arrow-point {
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid #f0f0f0;
  position: absolute;
  right: -1px;
  top: 40%;
}

.side-nav li ul {
  display: none;
}

.small,
small {
  font-size: 85%;
}

/* dashboard-main custom-col-width */
.messageDivWrap {
  background-color: white;
}


.nav-tabs li a {
  background: transparent !important;
}

.custom-col-width {
  width: calc(100% - 130px);
  padding: 0;
  float: left;
}

.dashboard-main {
  margin-left: 130px;
  margin-top: 80px;
}

.notification-box-error {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
}

.notication-box,
.notification-box-error,
.notification-box-success {
  float: none;
  left: 0;
  margin: auto;
  position: fixed;
  right: 0;
  text-align: center;
  width: 30%;
  z-index: 100;
  top: 9px;
  min-width: 344px;
  display: flex;
}

.fluid-container {
  padding: 0 15px;
  margin: auto;
}

.main-content {
  padding: 10px 10px 26px;
  display: inline-block;
  width: 100%;
}

.main-content .right-search-area {
  margin-top: 0;
  padding-right: 0;
  float: right;
  width: 50%;
}

.right-search-area .create-btn {
  float: right;
}

.create-btn {
  margin-left: 15px;
  background: #007d66;
}

.btn {
  background-color: #02857a;
  color: #fff;
  font-size: 16px;
  border: 0;
  padding: 4px 20px;
  height: 40px;
  min-width: 115px;
  border-radius: 3px;
  line-height: 32px;
  font-weight: 400;
}

.search-block {
  background: #fff none repeat scroll 0 0;
  border: 1px solid #ddd;
  border-radius: 3px;
  float: right;
  height: 40px;
  padding: 0;
}

.search-block input {
  float: left;
  width: 88%;
  height: 40px;
}

input {
  background: transparent;
  border: 0 none;
  color: #555;
  font-size: 16px;
  padding: 6px 12px;
  font-weight: 300;
}

.search-block span.search-icon {
  position: absolute;
  right: 0;
  top: 7px;
  width: 30px;
  height: 36px;
  cursor: pointer;
  font-size: 22px;
  color: #bfbfbf;
}

/* nav nav-tabs  */

.main-content .nav-tabs {
  margin-bottom: 20px;
  padding-bottom: 1px;
  border-bottom: 1px solid #d1d1d1;
}


.nav-tabs > li.active > a,
.nav-tabs > li.active > a:focus,
.nav-tabs > li.active > a:hover {
  border: 0 !important;
  color: #3a3f41;
  border-bottom: 3px solid #4a90e2 !important;
}

/* Card Css  */

.card {
  border-radius: 3px;
  overflow: hidden;
  margin-bottom: 30px;
}

.nocls-add .card a {
  display: flex;
}

.tab-content .card {
  position: relative;
}

.card .card-dropdown {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 15px;
  top: 8px;
  /* z-index: 1; */
  color: #fff;
  cursor: pointer;
  width: 8%;
  height: 14%;
 
  padding: 5px 2px 3px 3px;
  border-radius: 3px;

}
.incompleted-card{
   background: #6c6cc8;
     /* border: 1px solid #8383e8; */
}
.completed-card{
  background: #02857a;
  /* border: 1px solid #37e954; */
}

.card div {
  float: left;
  width: 100%;
}

.card .card-dropdown .dropdown-menu {
  color: #656565;
  padding: 5px 8px 4px;
  left: auto;
  right: 6px;
  width: 120px;
  min-width: 0;
  border-radius: 3px;
  margin-top: 0;
}

.card .card-dropdown .dropdown-menu li:hover {
  background-color: #f5f5f5;
  color: #262626;
}

.card-upper-data {
  color: #fff;
  background: #6565c9;
  font-size: 16px;
}
.classNameroom-tab {
  color: #fff;
  /* background: #6565c9; */
  font-size: 16px;
}

.fa-ellipsis-vertical {
  font-size: 16px;
}

.pad-15 {
  padding: 15px;
}

.card-upper-data h3,.classNameroom-tab h3 {
  margin-top: 0;
  border-bottom: solid 1px rgba(255, 255, 255, 0.1);
  padding-bottom: 10px;
  font-size: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  padding-right: 28px;
}

.subject_info {
  margin-bottom: 10px;
}

.subject_info,
.address_info , .section_info {
  font-size: 14px;
}

.book {
  background-position: -73px -8px !important;
}

.caret,
.classroom,
.strands,
.reports,
.student,
.book,
.map,
.admin,
.viewReport,
.move,
.reset {
  background: url(../imgs/lotusbg.png) no-repeat;
}

.address_info {
  float: none;
  line-height: 18px;
  height: 42px;
}

.section_info{
  margin-bottom: 10px;
}

.sectionIcon {
  background-position: -73px -8px !important;
  margin-left: 3px;
}

.map {
  background-position: -53px -8px !important;
}

/* Disabled dates */
.disabled-date {
  background-color: #808080!important; /* Light red background */
  color: white !important; /* Dark red text */
  border: 1px solid black !important;
  opacity: 0.6;
}

/* Present dates */
.present-date {
  background-color: green!important; 
  color : rgb(255, 255, 255) !important;
  border: 1px solid grey !important;
}

/* Absent dates */
.absent-date {
  background-color: red  !important; /* Light red background */
  color: rgb(255, 255, 255) !important; /* Dark red text */
  border: 1px solid grey !important;
}

/* Not marked dates */

.not-marked-date {
  background-color: DodgerBlue  !important; 
  color: rgb(255, 255, 255) !important; 
  border: 1px solid grey !important;
}


.status-indicator {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.indicator-box {
  min-width: 30px;
  height: 15px;
  margin-right: 15px;
  border-radius: 8px;
  display: inline-block;
  border: 1px solid black !important;
}

/* General tile styles */
.attendance-overview-table .react-calendar__tile {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
}

.attendance-overview-table .react-calendar__month-view__days__day{
  pointer-events: none;
}

.attendance-overview-table button.react-calendar__tile.react-calendar__tile--now.react-calendar__month-view__days__day.disabled-date  {
  background-color: #808080!important; /* Light red background */
  color: white !important; /* Dark red text */
  pointer-events: none;
  opacity: 0.6;
}

.attendance-overview-table button.react-calendar__tile.react-calendar__tile--now.react-calendar__month-view__days__day.present-date {
  background-color:green!important; 
  color : rgb(255, 255, 255) !important;
  pointer-events: none;
}

.attendance-overview-table button.react-calendar__tile.react-calendar__tile--now.react-calendar__month-view__days__day.absent-date {
  background-color: red  !important; /* Light red background */
  color: rgb(255, 255, 255) !important; /* Dark red text */
  pointer-events: none;
}

.attendance-overview-table button.react-calendar__tile.react-calendar__tile--now.react-calendar__month-view__days__day {
  background-color: DodgerBlue !important; /* Light red background */
  color: rgb(255, 255, 255) !important;  /* Dark red text */
}

.attendance-overview-table .react-calendar__month-view__weekdays__weekday {
  border: 1px solid grey !important;
}

.card-lower-data {
  padding: 10px 15px !important;
  background: #fff;
  color: #434343;
  font-size: 16px;
}

.card .student_info {
  font-size: 14px;
}

.card .student_info .nstud {
  width: 100%;
  color: #283940;
  min-width: 110px;
}

.pull-left {
  float: left !important;
}

.student {
  background-position: -33px -8px !important;
  cursor: pointer;
}

.student,
.book,
.map {
  float: left;
  height: 20px;
  width: 20px;
  margin-right: 8px;
}

.pull-right {
  float: right !important;
}

.student-completed {
  color: #767676;
  text-align: right;
  width: 100%;
  float: left;
  font-size: 12px;
}

.status-bar-block {
  float: left;
  padding: 5px 0 5px 30px;
}

.status-bar {
  height: 6px;
  width: 100%;
  background: #e0e0e0;
  border-radius: 10px;
  position: relative;
}

.card div.status-process {
  position: absolute;
  background: #4acdc7;
  width: 0%;
  height: 6px;
  border-radius: 10px;
}

/*********************/

.text-terms {
  float: none;
  font-size: 28px;
  line-height: 40px;
  margin: auto;
  padding: 50px;
}

input#search::placeholder {
  color: #afa9a9;
}

.classroom-tab {
  padding-bottom: 0;
}

.side-nav li.active > ul li:hover a,
.side-nav li:hover > ul li:hover a {
  background: #454562;
}

.side-nav li:hover > ul li a {
  padding: 11px 0 11px 20px;
  text-align: left;
}

.sidebar-menu:hover .sub-menu {
  display: block;
  width: 150px;
}

.sub-menu {
  position: absolute;
  left: 130px;
}

/* For Jquery Add Class  */

.borderBottom {
  border-bottom: solid 3px #9956b5 !important;
}

/* For Academic Strands  */
.strands-view {
  display: block;
  width: calc(100vw - 172px) !important;
  margin-left: 131px;
  padding-top: 83px;
}

.strands-view .strands-subgrade-heading {
  border-bottom: 1px solid #ddd;
  margin-bottom: 15px;
  padding-bottom: 5px;
}

.strands-view .strands-subgrade-heading h4 {
  font-weight: 400;
}

.strands-view .pad-15 {
  padding: 0;
}

.strands-view .card-upper-data h3 ,.strands-view .classNameroom-tab h3{
  margin-bottom: 0;
  padding: 10px 15px;
}

.strands-view .card-lower-data {
  padding: 15px;
  min-height: 120px;
}

/* For Student Report  */

#stuRepForm {
  display: none;
}

.row-1 {
  padding-left: 115px;
}

.RepDashBoard {
  margin-left: 130px;
  margin-top: -60px;
}

.container-fluid {
  padding: 73px 11px;
  padding-right: 53px;
  margin-right: auto;
  margin-left: auto;
}

.dashboard-main .breadcrum {
  position: fixed;
  z-index: 10;
  background: #f0f0f0;
  width: calc(100% - 150px);
  border-bottom: 1px solid #ccc;
  left: 167px;
  padding-top: 5px;
  top: 84px;
}

.breadcrum {
  color: #6b6e70;
  font-size: 15px;
  margin-top: -9px;
  border-bottom: 1px solid #ccc;
  margin-left: 14px !important;
  width: calc(100% - -139px);
}

.dashboard-main .head-content {
  font-size: 16px;
  border: 0;
  margin-top: 30px;
  margin-bottom: 6px;
}

.tab.active_tab {
  display: block;
}

.nav-tabs > li {
  margin-right: 15px;
  float: left;
  margin-bottom: -1px;
}

.nav-tabs > li > a {
  border-bottom: 3px solid #eee;
  outline: none;
  color: #686e81;
  font-size: 16px;
  padding: 10px 24px;
  margin-right: 2px;
  line-height: 1.42857143;
  border: 1px solid transparent;
  border-radius: 4px 4px 0 0;
}

.nav > li > a {
  position: relative;
  display: block;
  padding: 10px 15px;
}

label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 400;
}

.asterik {
  color: #e00000;
  font-size: 12px;
  position: absolute;
  margin-top: 0;
}

.report.button-block {
  margin-top: 20px;
}

#generateBtn {
  background: #02857a;
  color: #fff;
  font-size: 16px;
  border: 0;
  padding: 4px 20px;
  height: 40px;
  min-width: 115px;
  border-radius: 3px;
  line-height: 32px;
  font-weight: 400;
}

.link {
  color: #6e6e6e;
  display: inline-block;
  font-size: 16px;
  margin-top: 0;
  padding: 6px 20px;
  text-decoration: none;
  vertical-align: middle;
  font-family: "Roboto", sans-serif;
}

.reportsSec {
  margin-left: 10px;
}

.reportsStudent {
  width: calc(100% - 255px);
}

.breadcrum .breadcrumb-blue {
  color: #0072bc;
}

.row {
  margin-left: 1px;
}

.form-group1 {
  padding: 124px 0px 14px;
}

.reportblue {
  position: fixed;
  z-index: 10;
  background: #f0f0f0;
  width: calc(100% - 150px);
  border-bottom: 1px solid #ccc;
  left: 140px;
  padding-top: 1px;
  top: 79px;
}

.underreportblue {
  margin-left: 131px;
}

/* # Move request */
.dataTableInMoveReq {
  padding: 105px 8px 26px;
}

.add-student-modal #student_table_length {
  margin-bottom: 0;
}

.add-student-modal #student_table_length label {
  margin: 0;
  float: right;
  font-size: 14px;
  font-weight: 400;
}

#customFilterDiv {
  float: left;
  margin-left: 271px !important;
  margin-top: -53px;
}

.class-report-table-wrapper,
.overflow-table,
.responsive-table {
  width: 100%;
  display: block;
}

.responsive-table {
  overflow: auto !important;
  white-space: nowrap;
  max-height: 421px;
}

.select-student table#userStatus,
.select-student table#example,
.select-student table#student_table,
#userList table {
  border: solid 1px #ddd;
}

#student_table_wrapper table {
  position: relative;
}

table thead {
  position: sticky;
  top: -1px;
  background: #3177c9 !important;
  color: #fff !important;
  z-index: 0;
}
table td{
  padding: 8px 15px;
  text-align: left;
  vertical-align: middle;
  font-weight: 300;
  font-size: 13px;
  color: rgb(27, 27, 27);
  border: 1px solid rgb(203 188 188 / 10%);
}
table th {
  padding: 8px;
  font-size: 14px;
  text-align: left;
  font-weight: 500;
  text-transform: uppercase;
  background: #3177c9 !important;
  white-space: nowrap;
  border: 1px solid white;
}
.student_table tr th:first-child,
.move-student-show .student_table tr td:first-child {
  width: 25px !important;
}

.dt-example {
  padding-right: 23px;
  padding-left: 156px;
}

/* For Un-Assign  */
.classroom-info label {
  font-size: 16px;
  font-weight: 400;
  display: block;
}

ul.token-input-list {
  z-index: 1;
  width: 348px;
  position: absolute;
  background: white;
  cursor: pointer;
  max-height: 175px;
  overflow: auto;
}

ul.token-input-list li {
  list-style-type: none;
  border-bottom: 1px solid #e7e7e7;
  padding: 5px;
  padding-left: 14px;
}

ul.token-input-list li:hover {
  background-color: rgb(221, 219, 219);
}

#userNameDiv > ul.token-input-list li input {
  width: 348px !important;
  font-size: 14px;
  display: block;
  padding: 6px 12px !important;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border-radius: 3px;
  height: 40px;
}

ul.token-input-list li input {
  border: 0;
  padding: 3px 8px;
  background-color: white;
  width: 253px;
}

.token-input-input-token > input {
  min-width: 143px;
}

.unAssign-input input {
  width: 348px;
  font-size: 14px;
  padding: 6px 12px;
  color: #555;
  background-color: #fff;
  border-radius: 3px;
  height: 40px;
}

.strands-help-heading h4 {
  border-bottom: 1px solid #ddd;
  font-weight: 400;
  margin-bottom: 15px;
  padding-bottom: 10px;
}

.UnAssign {
  padding-left: 167px;
}

.UnAssignPre-test {
  padding-left: 176px;
}

.main-content-unassign {
  padding: 76px 159px 37px;
}

.main-content-unassign-pretest {
  padding: 78px 159px 26px;
}

/* createClassRoom css */

.create-classroom-form,
.create-workshop-form {
  background: #fff;
}

.create-classroom-form form,
.create-workshop-form form {
  max-width: 1070px;
}

.create-classroom-form form .form-group,
.create-workshop-form form .form-group {
  position: relative;
}

.form-group {
  margin-bottom: 15px;
  padding: -3px 6px 14px;
}

.form-control {
  display: block;
  width: 100%;
  height: 40px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  font-family: inherit !important;
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control,
select.form-control:disabled {
  background-color: #e1e1e1;
}

.bootstrap-select.btn-group > .btn {
  height: 40px;
  border-radius: 3px;
  line-height: 32px;
  box-shadow: none;
  background: transparent;
  font-weight: 400;
  font-size: 14px;
  color: #555;
  padding-right: 30px;
}

.bootstrap-select.btn-group .dropdown-toggle .filter-option {
  display: inline-block;
  overflow: hidden;
  width: 100%;
  text-align: left;
}

.bootstrap-select.open > .dropdown-toggle.btn-default {
  background: #fff;
  border: 0;
  color: #333;
}

.bootstrap-select.btn-group .dropdown-menu.inner {
  position: static;
  float: none;
  border: 0;
  padding: 0;
  margin: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.button-block {
  margin-top: 30px;
}

#confirmPercentageBtn {
  background: #02857a;
  color: #fff;
  font-size: 16px;
  border: 0;
  padding: 4px 20px;
  height: 40px;
  min-width: 115px;
  border-radius: 3px;
  line-height: 32px;
  font-weight: 400;
}

#classroom_form {
  background: white;
  padding: 13px;
  padding-left: 0 !important;
}

.disableinputfield {
  border: 1px solid #e6e5e5 !important;
  padding-bottom: 40px !important;
}

.CreateClassRoom {
  height: 60px;
}

/* .CreateClsRoomWrap {
  height: 650px;
  width: calc(100vw - 180px);
  margin-left: 148px;
  margin-top: 15px;
} */
.applyFiltersModalBody .CreateClsRoomWrap{
  margin-left: 0px;
  margin-top: 0px;
  width: 100%;
}

.CreateClsRoomWrap {
  /* / height: 650px; / */
  width: calc(100vw - 180px);
  margin-left: 148px;
  margin-top: 76px;
}

/* For Modal  */

.modal-Info {
  font-size: 22px;
  font-weight: 500;
  line-height: 25px;
  color: #60676a;
  margin: 0;
  text-align: center;
}

.modal-body p {
  opacity: 0.7;
  font-size: 15px;
  line-height: 18px;
  color: #60676a;
  text-align: center;
  width: 550px;
}

.modal-footer {
  padding: 30px 15px;
  height: auto;
  border: 0;
  text-align: center;
}

.modal-footer #ModalOkbtn,
#shareClassroomBtn {
  opacity: 1;
  box-shadow: 0 0 2px #ddd;
  background: #008671;
  color: #fff;
}

.modal-header {
  min-height: 16.43px;
  padding: 15px;
  border-bottom: none;
}

.createbtn {
  background: #008671;
}

.createclsmodal {
  z-index: 99999 !important;
}

.FieldsWrap {
  display: flex;
  margin-bottom: 16px;
}

.FieldsWrap .field1,
.field2,
.field3,
.field4,
.field5,
.field6 {
  width: 50%;
}

.field7 {
  width: 48%;
}

.mainForm {
  padding: 25px;
}

.FieldsWrap .field1,
.field3,
.field5 {
  margin-right: 17px;
}

.finalsubmitBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
}

.formClass {
  padding: 0 !important;
}

.finalsubmitBtn input,
.finalsubmitBtn .login-btn {
  width: 0 !important;
  height: 45px;
  border-radius: 5px;
  background: #8e44ad;
  border: 1px solid #8e44ad;
  color: #fff;
  font-size: 20px;
  font-weight: 400;
  margin-top: 24px;
}

/****************** logout model css start *******************/
.dismissBtn {
  position: absolute;
  top: 10px;
  right: 16px;
}

.modalIconWrap i {
  font-size: 50px;
}

.modalIconWrap {
  display: flex;
  margin: auto;
  justify-content: center;
  align-items: center;
}

.logoutModalBody {
  padding: 0;
}

.color-fade {
  color: #767676;
}

/* Table Css  */

table {
  width: 100%;
}


tbody {
  background: white !important;
}

.tbl-content {
  max-height: 398px;
  overflow-x: auto;
  overflow-y: auto;
  margin-top: 0px;
  border: 1px solid rgba(136, 132, 132, 0.1);
}



/* td {
  padding: 8px 15px;
  text-align: left;
  vertical-align: middle;
  font-weight: 300;
  font-size: 14px;
  color: black;
  border: 1px solid rgb(203 188 188 / 10%);
} */

.firsttd td {
  padding: 29px 20px;
}

aside.side-nav.fixed::-webkit-scrollbar,
#sidebar-menu.fixed::-webkit-scrollbar,
.lotusSidebar::-webkit-scrollbar {
  width: 3px;
  background: #f2f2f2;
}
.modalInner-mail-dropdown::-webkit-scrollbar-thumb,
ul.token-input-list::-webkit-scrollbar-thumb,
.lotusSidebar::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.6);
}
/* .table-responsive::-webkit-scrollbar,#sidebar-menu::-webkit-scrollbar {
  width: 6px;
  height: 6px;
} */

/* .table-responsive::-webkit-scrollbar-thumb, */
.modalInner-mail-dropdown::-webkit-scrollbar-thumb,
ul.token-input-list::-webkit-scrollbar-thumb  {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  border: 1px solid #f2f2f2;
}

.table-responsive td:nth-child(2) {
  white-space: nowrap;
  width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.table-responsive th:nth-child(2) {
  width: 180px;
}

/* Search input field */

.searchWrap {
  margin-bottom: 12px;
}

.searchInputField {
  padding: 10px 18px 10px 33px;
  border-radius: 8px;
  border: 1px solid rgb(56 54 54 / 10%);
  margin-left: -17px;
  background-color: white;
}

.search_glass {
  position: relative;
  left: 9px;
  top: 3px;
  font-size: 17px;
  color: #6e6e6e;
}

input.searchInputField:focus {
  outline: none;
}

/* select field  */

#moveRequestStatusOption {
  margin-left: 22px;
  height: 40px;
}

/* another select field  */

#student_table_length select {
  padding: 10px 18px 10px 33px;
  border-radius: 8px;
  border: 1px solid rgb(56 54 54 / 10%);
  background: white;
}

/* Table in ClassroomReport  */

.clsReportTable {
  width: calc(100vw - 195px);
  margin-left: 146px;
}

.badge-pill1 {
  background: #28a745;
  padding: 8px 16px 8px 16px;
}

.badge-pill2 {
  background: #17a2b8;
  padding: 8px 16px 8px 16px;
}

/* card-dropdownMenu */
.card-dropdownMenu {
  font-size: 12px;
}

.card-dropdownMenu > li > a {
  padding: 3px 1px;
  color: #656565;
}

.card-dropdownMenu > li > a > svg {
  margin-right: 5px;
}

/* New Modal Open in Dashboard  */

.content-modal .modal-header {
  padding: 30px 15px;
  border-bottom: none !important;
}

.content-modal .modal-title {
  font-size: 22px;
  line-height: 25px;
  color: #60676a;
  font-weight: bold;
}

.content-modal .modal-body {
  padding: 15px 32px;
}

.modal-body .token-input-list input {
  z-index: 9999;
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 3px;
  height: 38px;
  padding-left: 14px;
}

button#dLabel {
  padding: 7px 10px;
  height: 38px;
  background: transparent;
  border: 1px solid #ccc;
  border-radius: 3px;
  color: #666;
}

.modal-body #newuserList {
  overflow: auto !important;
  white-space: nowrap;
  padding-top: 55px;
}

.modal-foot {
  text-align: center;
  border-top: none !important;
  padding: 30px 15px;
}

.dropdownin-modal {
  left: auto;
  right: 0;
  width: 220px;
  color: #666;
}

.dropdownin-modal li {
  font-size: 15px;
  line-height: 30px;
  color: #60676a;
  padding: 5px 0 5px 12px;
}

.dropdownin-modal li label {
  margin-right: 10px;
}

.newmodalBtn {
  opacity: 1;
  box-shadow: 0 0 2px #ddd;
  background: #008671;
  color: #fff;
}

.email-btn-iconWrap {
  width: 537px;
  margin-left: -15px;
}

table .firstrow-newuserListTable th {
  width: 125px;
}

.shareClsEmail {
  white-space: nowrap;
  width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* for dashboard modal dropdown  */
.modalInner-mail-dropdown {
  width: 238px;
  position: absolute;
  background: white;
  border: 1px solid #e7e7e7;
  max-height: 97px;
  overflow: auto;
  z-index: 9999 !important;
  overflow-x: hidden;
}

.spanVal {
  margin-left: 13px;
}

.spanValWrap {
  border-bottom: 1px solid #e7e7e7;
  padding: 5px;
}

.spanValWrap:hover {
  background-color: rgb(221, 219, 219);
}

/*  delete share classrom style css */
.msgOuterWrap {
  padding: 15px 17px 15px 17px;
  position: absolute;
  background: #e9e9e9;
  border-radius: 15px;
  z-index: 9999 !important;
}

.msgHead {
  margin-bottom: 10px;
  font-weight: bold;
  color: black;
}

.msgBtnWrap .btn {
  min-width: 10px !important;
  height: 27px !important;
  line-height: 1;
  margin-right: 5px;
}

#lang-change {
  position: absolute;
  right: 10px;
  top: 10px;
}

.wrapCanvas {
  width: calc(100vw - 160px);
  margin-left: auto !important;
}

/*  Strands accordion css */
.strandsAccordion {
  margin-left: 28px;
  width: calc(100vw - 258px);
  margin-top: 2px;
}

.strandsAccordion .panel-heading {
  height: 60px;
}

.strandsAccordion .panel-title {
  line-height: 3.4;
  font-weight: bold;
}

.accbody {
  width: 100%;
}

/*  profile view css  */

/* profile view  */

.profileContainer {
  margin-left: 145px;
  background-color: white;
  margin-top: 78px;
  width: 100%;
}

.viewProfileSideBar {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 50px;
}

.profileCreateClass {
  display: flex;
}

.viewProfileSideBar h4 {
  font-size: 22px;
  font-weight: 400;
}

.viewProfileSideBar svg {
  font-size: 145px;
}

.profileFormWrap {
  margin-left: -32px;
}

/* Pretest   */

.newUnassignPagecontent {
  width: calc(100vw - 209px);
  margin-left: 139px;
  margin-top: 83px;
}

.block-wrapper-white {
  margin-top: 29px;
  background: white;
  padding: 30px;
}

.block-wrapper-white label {
  font-size: 15px;
  line-height: 18px;
  color: #707689;
  font-weight: 400;
}

.block-wrapper-white p {
  font-size: 27px;
  font-weight: 300;
  line-height: 32px;
  color: #283940;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}

.truncate {
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pretest h4 {
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
  color: #60676a;
  text-align: center;
  margin: 50px 0 5px;
}

.pretest .sub-heading {
  font-size: 14px;
  line-height: 16px;
  color: #707689;
  text-align: center;
}

.pretest .form-inline {
  max-width: 700px;
  margin: 35px auto;
}

.pretest .form-inline .form-group {
  width: 37%;
  position: relative;
  margin: 0 3px;
}

.pretest .form-inline .form-group input,
.pretest .form-inline .form-group select {
  width: 100%;
  font-size: 14px;
  line-height: 16px;
  color: #61616f;
  height: 40px;
  font-weight: 400;
}

.calendarc-icon {
  position: absolute;
  top: 32px;
  right: 10px;
  font-size: 20px;
  color: #8f95a5;
  cursor: pointer;
}

.chosen-single {
  border-radius: 3px;
  border: 1px solid #e4e4e4;
  height: 40px;
  padding: 10px;
  position: absolute;
  width: 100%;
  top: 29px;
}

.grade-radio-options label {
  display: inline-flex;
  margin-right: 40px;
}

.grade-radio-options input {
  margin-right: 15px;
  width: 16px;
  height: 16px;
  border: 2px solid #ccc;
  border-radius: 100%;
  text-align: center;
  padding: 0;
  line-height: 11px;
  float: left;
}

.learningtabcont {
  background: white !important;
  position: absolute;
  width: calc(100vw - 228px);

  min-height: 102px;
  padding-top: 16px;
}

.create-learning-form .grade-radio-options label span.text {
  color: #60676a;
  font-size: 16px;
}

.datepick {
  width: 255px !important;
}

/* Bar Graph */
.barGraphCanvasData {
  width: calc(100vw - 196px) !important;
  margin-left: 143px;
}

/*  react select css */
.selectBox {
  width: 180px;
}

/* Question Report */

.q-option {
  width: 25px;
  height: 25px;
  margin-right: 10px;
  line-height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: white;
  margin-top: 5px;
  /* font-weight: 100; */
}

.darkgray {
  background: darkgray;
}

.red {
  background-color: #d44242 !important;
}

.green {
  background-color: #008946;
}

.report_noClass {
  background-color: transparent;
  border: 0 solid #ccc;
  font-size: 14px;
  padding: 0;
  word-break: break-all;
  word-wrap: break-word;
  line-height: 2;
}

.ansWrap {
  display: flex;
  margin-top: 16px;
}

.clsReportTable td {
  border: none;
}

/*  for counseller modal */

.counsellerModal-header {
  background: #9956b5;
  color: #fff;
  padding: 10px 15px;
}

.counsellerInner {
  margin-bottom: 15px;
}

.counsellerInner p {
  text-align: start;
}

/* parent teacher student modal  */

.input-group {
  display: inherit;
}

.datePickerStudent {
  display: block;
  width: 100%;
  height: 40px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.react-date-picker__wrapper {
  border: none !important;
}

.react-date-picker__calendar {
  inset: auto auto 45px 0px !important;
  z-index: 99 !important;
}

.dobWrap .react-date-picker__inputGroup {
  min-width: 106px;
  padding: 0 !important;
}

button.react-date-picker__clear-button.react-date-picker__button {
  margin-left: 84px;
}

.ortext {
  position: absolute;
  background: #999;
  border-radius: 100%;
  height: 25px;
  width: 25px;
  line-height: 27px;
  text-align: center;
  font-weight: 500;
  color: #fff;
  margin: 0px;
  left: 269px;
  right: 0px;
  margin-top: 34px;
  font-size: 10px;
  display: initial;
}

.notifycheckboxes {
  display: inline;
  margin-left: 20px;
}

.notifycheckboxes label {
  margin-right: 10px;
}

input#btnTest:focus {
  outline: none !important;
}

/* eye password icon css */
.eye {
  margin-top: 10px;
  margin-left: 10px;
}

/* For DatetimePicker */

.datePickerWrap1 .react-datetime-picker__wrapper {
  border: 1px solid hsl(0, 0%, 80%) !important;
  min-height: 38px;
  border-radius: 4px;
}

.datePickerWrap1 .react-datetime-picker__inputGroup {
  padding: 0px 12px 0px 11px !important;
}

.datePickerWrap1 .react-datetime-picker__calendar {
  width: 265px !important;
}

.datePickerWrap1 .react-datetime-picker__clock {
  height: 182px !important;
  border: none !important;
}

.datePickerWrap1 .react-calendar__tile--now,
.datePickerWrap2 .react-calendar__tile--now,
.form-group .react-calendar__tile--now {
  background: rgb(210 189 219 / 90%) !important;
}

.datePickerWrap1 .react-calendar__tile--active,
.datePickerWrap2 .react-calendar__tile--active,
.form-group .react-calendar__tile--active,
.react-calendar__tile--hasActive {
  background: #3177c9 !important;
  color: white !important;
}

.datePickerWrap1 .react-clock__face,
.datePickerWrap2 .react-clock__face {
  box-shadow: 1px 4px 16px #ad9999 !important;
}

.datePickerWrap1
  button.react-datetime-picker__clear-button.react-datetime-picker__button,
.datePickerWrap2
  button.react-datetime-picker__clear-button.react-datetime-picker__button {
  display: none;
}

.assignPretestLpdropdownbox,
.dueDateWrap {
  float: right;
}

.datePickerWrap2 .react-datetime-picker__wrapper {
  border: none !important;
}

.datePickerWrap2
  .react-datetime-picker.react-datetime-picker--open.react-datetime-picker--enabled {
  float: right;
}

.datePickerWrap2 .react-datetime-picker__clock {
  margin-left: -21px;
  border: none;
}

#dobDivForgot .react-calendar {
  margin-left: -99px;
}

#userDobDatePickerForgot .react-calendar {
  position: absolute !important;
  margin-left: -99px !important;
}

.form-group
  svg.react-date-picker__clear-button__icon.react-date-picker__button__icon {
  display: none;
}

/* For all types of dropdown  */
button#about-us {
  background: white;
  color: #423d3d;
  border: 1px solid hsl(0, 0%, 80%) !important;
  height: 41px !important;
  min-width: 199px;
}

button#about-us:focus {
  outline: none !important;
}

.caret-duration {
  margin-left: 11px !important;
}

.menu-duration {
  min-width: 199px;
  padding: 10px 0px 0px 10px;
  max-height: 175px;
  overflow: auto;
}

.menu-duration li {
  padding-bottom: 8px;
  cursor: pointer;
}

/* CurrentLearningPlan styling  */

.leftSideAccordion {
  background-color: #ececec;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  /* float: left; */
  max-width: 325px;
  width: 100%;
  height: calc(100vh - 100px);
  /* position: fixed; */
  min-height: 0;
  max-height: inherit;
}

.leftSideAccordionInner {
  position: relative;
  overflow: hidden;
  height: 100%;
  max-width: 100%;
  outline: none;
  direction: ltr;
}

div#mCSB_1_container {
  margin-right: 0;
}

.leftSideAccordion ul li {
  padding: 17px 15px 15px;
  font-size: 16px;
  color: #7f868f;
  position: relative;
  box-shadow: none;
  margin: 0;
  list-style: none;
}

.past-learning-plan .view-learning-plan-modal .sidebar li.active {
  background: #fff;
}

/* classroom css start */
.zoomIn {
  animation-name: zoomIn;
}

@keyframes zoomIn {
  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
}

/* 404 Error Page  */

.loginInner {
  float: left;
  width: 100%;
  font-family: "Roboto", sans-serif;
  overflow: auto;
  position: absolute;
  height: 100vh;
  background: transparent url(../imgs/404error.jpg) center center !important;
  background-size: contain !important;
}

.goBackBtn button {
  padding: 10px 20px;
  background: #007d66;
  border-radius: 3px;
  color: #fff;
  font-size: 24px;
  position: absolute;
  width: 184px;
  text-align: center;
  margin: auto;
  left: 0;
  right: 0;
  top: 72%;
  border: none;
}

.goBackBtn button:focus {
  outline: none;
}

/* question-instruction styling  */

.editOptions {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: transparent;
  font-size: 16px;
  padding: 0px;
  min-height: 25px;
  /* width: 85px; */
}

.editOptions:hover {
  background-color: rgb(225 224 224);
}

.editOptions:hover .pencilEditIcon {
  visibility: visible !important;
}

.optionsWrap .inputUnderOption {
  border: 1px solid #d1cbcb !important;
  padding: 0px 39px 0px 3px !important;
}

/*  current lp */
.tabscontentlpWrap .tabscontentlp {
  display: block;
  border: none;
}
.compareBtn {
  margin-top: 24px;
  padding: 9px 14px 9px 13px;
  background: #ccc;
  border: 1px solid #ccc;
  color: white;
}

/* super admin  */

.coldatepicker .react-calendar {
  margin-left: 0 !important;
}

.table-responsive {
  width: 100% !important;
  max-height: 350px !important;
  white-space: nowrap;
  overflow: auto;
}
.table-responsive table {
  margin-bottom: 0 !important;
}
.table-responsive table thead tr th {
  white-space: nowrap;
}
.table-responsive table tbody tr span {
  width: 250px !important;
  display: block;
}
.table-responsive table tbody tr span.gradesTypeIntd{
  width: auto !important;
}
.clsroomsidebar .card-link {
  font-size: 35px;
}
.clsroomsidebar .card-body label {
  font-size: 35px;
  color: #266eaf;
}
.clsroomsidebar .card-title {
  font-size: 19px;
}
.paginationWrap {
  position: relative;
  top: 15px;
  z-index: 0;
}

.paginationWrap .responive-pagination {
  position: absolute;
  right: 38%;
}
.rs-picker-toggle-wrapper {
  width: 100%;
}
.districtsSelectAllStudents {
  margin-left: 20px;
  width: 248px;
}
.quickReportRow {
  display: flex;
  margin-bottom: 20px;
}

.compareBtn:not(:disabled) {
  background-color: #008671;
}

.side-nav li > ul li:hover ul {
  display: block !important;
}

.clsroomsidebar li {
  line-height: 1.2;
}

.clsroomsidebar .card .card-link:hover {
  text-decoration: none !important;
}

/* for login page EN down arrow  */
span#lang-name:after {
  content: "";
  position: absolute;
  border-bottom: 2px solid white;
  border-right: 2px solid white;
  padding: 3px;
  transform: rotate(45deg);
  top: 8px;
  right: -2px;
}

.exportBtnDropdown .dropdown-menu.show {
  display: flex !important;
  flex-direction: column;
}
.exportBtnDropdown .dropdown-menu.show a.dropdown-item {
  display: flex;
  align-items: center;
  padding: 5px;
  border-bottom: 1px solid #bbb;
  color: black;
}
.exportBtnDropdown .dropdown-menu.show a.dropdown-item:last-child {
  border: none;
}
.exportBtnDropdown .dropdown-menu.show a.dropdown-item:hover {
  text-decoration: none;
}
.exportBtnDropdown .dropdown-menu.show a.dropdown-item svg {
  font-size: 16px;
  margin-right: 9px;
  color: #565656;
}

.moveManuallyrow {
  margin-top: "40px";
  border-bottom: 1px solid #d5d5d5;
}
.moveManuallyrow label {
  width: 100px;
}
.moveManuallyrow .labelFieldWrap {
  margin-bottom: 30px;
}
.moveManuallyrow .labelFieldWrap:first-child {
  margin-top: 30px;
}
.moveManuallyBtnrow {
  margin-top: 20px;
}
.moveManuallyBtnrow .cancel {
  border: none;
  font-size: 16px;
  padding: 4px 20px;
  height: 40px;
  min-width: 115px;
  border-radius: 3px;
  line-height: 32px;
  font-weight: 400;
  background: transparent;
}

.moveSelectArea .form-select-control {
  margin-top: 10px;
}

.rejectBtn {
  background: transparent none repeat scroll 0 0;
  border: 1px solid;
  border-radius: 3px;
  color: #d44242;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  padding: 4px 15px;
}

.rejectBtn:hover {
  background-color: #d44242;
  color: white;
}
.approvedBtn {
  background: transparent none repeat scroll 0 0;
  border: 1px solid;
  border-radius: 3px;
  color: #02857a;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  padding: 4px 15px;
}

.approvedBtn:hover {
  background-color: #02857a;
  color: white;
}
.modal-backdrop.fade {
  opacity: 0.4;
}
.relationshipUserStatus {
  opacity: 1;
}

.associateBtn {
  background: transparent none repeat scroll 0 0;
  border: 1px solid #02857a;
  border-radius: 3px;
  color: #02857a;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  padding: 4px 15px;
}
.associateBtn:hover {
  background: #02857a;
  color: #fff;
  border-color: #02857a;
}

.modalRejectBtn,
.relationModalConfirmBtn {
  background-color: #d44242 !important;
  border: 1px solid #d44242 !important;
  color: white !important;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  padding: 8px 30px;
}

.pagination .active > .page-link {
  background-color: #3177c9 !important;
  border-color: #3177c9 !important;
  color: white !important;
}
.pagination .page-link {
  color: black !important;
}
.form-select-control#clsName2 {
  margin-left: 20px;
}
.sideBarBadge {
  position: absolute;
  top: 7px;
  right: 8px;
  background: #d44242;
  padding: 4px 10px;
  font-size: 10px;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  border-radius: 10px;
}

.sideBarInnerBadge {
  position: absolute;
  top: 12px;
  right: 7px;
  background: #d44242;
  padding: 2px 9px;
  font-size: 12px;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  border-radius: 10px;
}

.selectBox#teacherStatusOption {
  width: 97%;
}

.applyFiltersSelectField .applyFilterSelect1 {
  width: 46% !important;
}
.applyFiltersSelectField .applyFilterSelect2 {
  width: 46% !important;
  margin-left: 20px;
}
.applyFiltersSelectField .applyFilterSelect3 {
  width: 95% !important ;
}

.labelforUploadeImg {
  font-size: 21px;
  color: green;
  background: #e7e0e0;
  margin-left: 10px;
  height: 31px;
  min-height: 31px;
  min-width: 31px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.labelforRemoveImg{
  font-size: 21px;
  color: Red;
  background: #e7e0e0;
  margin-left: 10px;
  height: 31px;
  min-height: 31px;
  min-width: 31px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.fAFYss {
  position: relative;
  z-index: 9999 !important;
}

.searchInputType {
  background: white;
  margin-right: 18px;
  height: 38px;
}
.react-loading-skeleton {
  z-index: 0 !important;
}

.regasstudDatepicker {
  border: 1px solid hsl(0, 0%, 80%);
  border-radius: 4px;
  min-height: 38px;
  padding: 0px 13px;
}

.regasstudDatepicker .react-calendar {
  position: absolute !important;
  top: -263px !important;
  margin-left: 0px !important;
}


.reactTooltipOnHover {
  opacity: 0;
  visibility: hidden;
  width: 383px;
  border: 1px solid #898989;
  padding: 16px 30px;
  border-radius: 11px;
  position: absolute !important;
  background-color: white;
  z-index: 999;
  right: 52px;
  top: 36px;
  font-size: 12px;
  text-align: left !important;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
}
.forReactTooltipHoverIcon {
  cursor: pointer;
}
.forReactTooltipHoverIcon:hover ~ .reactTooltipOnHover {
  opacity: 1;
  visibility: visible;
}

.studentreportdropdownMenu {
  left: -105px ;
  top: 17px ;
  padding: 0 !important;
  cursor: pointer;
  z-index: 1 !important;
}

.studentreportdropdownMenu li {
  border-bottom: 1px solid #bbb;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
  display: flex;
}

.studentreportdropdownMenu li:hover {
  background: #e1dddd;
}

.studentreportdropdownMenu li svg {
  margin-right: 10px;
}

.quickReportdatepickerWrap button.rs-picker-toggle-clean.rs-btn-close {
  display: none;
}

.datePickerWrap1 .react-datetime-picker.react-datetime-picker--closed.react-datetime-picker--enabled{
  display: block !important;
}
.datePickerWrap1 .react-datetime-picker.react-datetime-picker--open.react-datetime-picker--enabled{
  display: block !important;
}

button:focus {
  outline: none !important;
  border: none !important;
}

.selectBoxactions {
  margin-right: 20px;
}

.gradeInfo {
  display: inline-block;
  padding: 0 15px;
  border-right: 1px solid #949799;
  text-align: center;
}

.gradeInfo:last-child{
  border-right: none !important;
}

.btn-default.active, .btn-default:active, .open>.dropdown-toggle.btn-default {
  color: #fff !important;
  background-color: #02857a !important;
  border-color: #02857a !important;
}
.btn-default.focus, .btn-default:focus{
  color: #fff !important;
  background-color: #02857a !important;
  border-color: #02857a !important;
}
.badge:empty{
  display: block !important;
}
.datePickerWrap1 select.react-datetime-picker__inputGroup__input.react-datetime-picker__inputGroup__amPm {
  position: absolute !important;
  width: 45px;
}
.imgCursor{
  cursor: pointer;
}
.unassign-input-cross{
  position: relative;
  right: 20px;
  cursor: pointer;
}
.unassign-modal-link{
  display: inline !important;
  color: #3177c9;
  cursor: pointer;
  text-decoration: none !important;
}
.unassign-modal-link:hover{
  color: #3177c9;
  border-bottom : 1px solid #3177c9
}
.lpTooltipOnHover {
  width: 163px;
  left: 230px;
  top: 131px;
  font-size: 12px;
  max-width: 380px;
  min-width: 380px;
  height: 100%;
  max-height: 300px;
  overflow: auto;
}
.lpTooltipOnHover span:not(:first-child) {
  color: #8d8989;
  margin-left: 18px;
}

.forReactTooltipHoverIcon:hover ~ .lpTooltipOnHover {
  opacity: 1;
  visibility: visible;
}
.sidebar-menu .active{
  background-color:#3b3a53 !important;
}

/* parent css start */
.parentAddress,
.parentSubject {
  max-width: 285px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.parentclsDisable {
  background-color: #a8a8b9;
  cursor: not-allowed;
}
.cardBottomRightside {
  position: relative;
  top: -19px;
}

.cancelBtn {
  color: #7d7474;
  font-size: 16px;
  border: 0;
  padding: 4px 20px;
  height: 40px;
  min-width: 103px;
  border-radius: 3px;
  line-height: 31px;
  font-weight: 400;
}

.addChildSection .addChildWrap .formWrap input:disabled {
  background: #bbb !important;
  cursor: not-allowed;
}

.addChildSection .addChildDatePicker {
  width: 100%;
  background: white;
  padding-right: 0;
}
.addChildSection
  button.react-date-picker__clear-button.react-date-picker__button {
  display: none;
}
.parentNoClsFound {
  width: 97%;
  background: white;
  margin-left: 16px;
  height: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 33px;
}
.parentViewProfile{
  padding: 0;
}

.parentViewProfile .accordion .accordion-header button {
  display: flex;
  gap: 20px;
  font-size: 17px;
  border: none;
  background: white;
  color: #266eaf;
}
#panel, #flip {
  padding: 5px;
  text-align: center;
  background-color: #e5eecc;
  border: solid 1px #c3c3c3;
}

#panel {
  padding: 50px;
  display: none;
}
.parentSectionOrText{
  position: absolute;
  top: 31px;
  left: 31px;
  background: #6e6e6e;
  height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: white;
}
/* parent css end */


/* public pages  */

@font-face {
  font-family: 'student-nest';
  src: url("/src/assets/webfonts/student-nest49ee.eot");
  src: url("/src/assets/webfonts/student-nest49ee.eot") format("embedded-opentype"), url("/src/assets/webfonts/student-nest49ee.ttf") format("truetype"), url("/src/assets/webfonts/student-nest49ee.woff") format("woff"), url("/src/assets/webfonts/student-nest49ee.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"],
[class*=" icon-"] {
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* / Better Font Rendering =========== / */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.navWrap{
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.enrollBtn {
  background: #ffc80a;
  border: 1px solid #ffc80a;
  color: #4c4c4c !important;
  font-size: 12px !important;
  text-transform: uppercase !important;
  cursor: pointer !important;
  transition: all 0.5s linear !important;
  letter-spacing: 2px !important;
  font-weight: 700 !important;
  position: relative !important;
  display: block !important;
  border-radius: 4px !important;
  padding: 10px 15px !important;
}

.enrollBtn:hover {
  text-decoration: none !important;
  outline: none !important;
  transition: all 0.5s linear !important;
  box-shadow: 0 3px 0px #555 !important;
  background-color: #eee !important;
  border: 1px solid #eee !important;
}

.homeNav {
  background: #623acd;
  position: sticky;
  top: 0;
  margin: 0;
  z-index: 2;
}

.homeNav .navbar-right {
  display: flex;
  align-items: center;
}

.homeNav .navbar-right li a {
  padding: 0.8em 1em;
  color: white;
  font-size: 13px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer !important;
  transition: all 0.3s linear;
}

.homeNav .navbar-right li a:hover {
  transition: all 0.35s linear;
  background: none;
  color: #ffc80a;
  border-radius: 0;
  cursor: pointer;
}


.homeNav .navbar-collapse {
  padding: 20px 16px;
}

.homeNavInnerSection .supportImgWrap img {
  width: 100%;
}

.homeNavInnerSection .supportImgWrap {
  padding-left: 0;
}

.homeNavInnerSection .innerSectionRigtSide {
  margin-top: 100px;
}

.homeNavInnerSection .innerSectionRigtSide h3 {
  color: #623acd;
  font-size: 40px;
  font-weight: 600;
  margin: 0;
}

.homeNavInnerSection .innerSectionRigtSide h3 strong {
  font-size: 50px;
}

.homeNavInnerSection .innerSectionRigtSide p {
  max-width: 480px;
  font-size: 16px;
  line-height: 28px;
  color: #707894;
  margin: 0;
}

.homeNavInnerSection .innerSectionRigtSide span.availabletext {
  margin-top: 30px;
  margin-bottom: 10px;
  display: block;
  font-weight: bold;
  font-size: 20px;
}

.homeNavInnerSection .innerSectionRigtSide .appLinks {
  display: flex;
  gap: 15px;
}

.homeNavInnerSection .innerSectionRigtSide .appLinks img {
  width: 150px;
}

.homeNavInnerSection .innerSectionRigtSide .appLinks span:last-child img {
  opacity: 0.6;
}

.homeNavInnerSection .innerSectionRigtSide .appLinks span strong {
  font-size: 12px;
  text-transform: uppercase;
}

.homeNavInnerSection .innerSectionRigtSide .appLinks span {
  color: #999;
}

.homeNavInnerSection .innerSectionRigtSide .appLinks span:last-child {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.homeNavInnerSection .promiseRow {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.homeNavInnerSection .promiseRow h3 {
  font-size: 38px;
  font-weight: 700;
  text-align: center;
  line-height: 52px;
  color: #2D4158;
  text-transform: uppercase;
  margin-bottom: 15px;
}

.homeNavInnerSection .promiseRow p {
  max-width: 790px;
  font-size: 16px;
  text-align: center;
  line-height: 28px;
  color: #707894;
  margin-bottom: 65px;
}

.homeNavInnerSection .boxContainerBlock {
  box-shadow: -1px 1px 0 #eeeeee, -1px 0 0 #eeeeee;
  height: 285px;
  box-sizing: border-box;
  margin: 1px 0;
  padding: 38px 15px;
}

.homeNavInnerSection .cardWrap {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.homeNavInnerSection .cardWrap h3 {
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
  color: #53565B;
  margin-top: 0;
}

.homeNavInnerSection .cardWrap p {
  font-size: 16px;
  line-height: 25px;
  color: #707894;
  max-width: 320px;
  text-align: center;
}

.homeNavInnerSection .cardWrap .userimgWrap {
  color: #7B61D5;
  padding: 25px 0 15px;
}

.homeNavInnerSection .cardWrap .userimgWrap svg,
.homeNavInnerSection .cardWrap .displayimgWrap svg,
.homeNavInnerSection .cardWrap .reportimgWrap svg,
.homeNavInnerSection .cardWrap .bookimgWrap svg,
.homeNavInnerSection .cardWrap .learningimgWrap svg{
  width: 60px;
  height: 44px;
}

.homeNavInnerSection .cardWrap .displayimgWrap {
  color: #7B61D5;
  padding: 25px 0 15px;
}

.homeNavInnerSection .cardWrap .bookimgWrap {
  color: #7B61D5;
  padding: 25px 0 15px;
}

.homeNavInnerSection .cardWrap .learningimgWrap {
  color: #7B61D5;
  padding: 25px 0 15px;
}

.homeNavInnerSection .cardWrap .reportimgWrap {
  color: #7B61D5;
  padding: 25px 0 15px;
}

.homeNavInnerSection .cardWrap .counsellingimgWrap {
  color: #7B61D5;
  padding: 25px 0 15px;
}

.icon-video-counsel {
  background-image: url(/src/assets/imgs/video-counselingpublicimages.svg);
  background-repeat: no-repeat;
  background-position: top center;
  width: 60px;
  height: 44px;
  margin: 25px 0 15px;
}

/* .icon-responsive:before {
  content: "\e904";
  font-size: 40px;
}

.icon-adaptive:before {
  content: "\e900";
  font-size: 40px;

}

.icon-report:before {
  content: "\e901";
  font-size: 40px;

}

.icon-user:before {
  content: "\e908";
  font-size: 40px;

}

.icon-zeroeffort:before {
  content: "\e909";
  font-size: 40px;

} */

.homeNavInnerSection .slide2 {
  margin-top: 0;
  position: absolute;
  top: 38%;
  left: 45%;
  width: 48%;
}

.homeNavInnerSection .slide3 {
  margin-top: 0;
  position: absolute;
  top: 66%;
  left: 64%;
  width: 32%;
}

.homeNavInnerSection .themeSection {
  height: 70vh;
  max-width: 440px;
  padding-top: 12%;
}

.homeNavInnerSection .themeSection h3 {
  font-size: 30px;
  line-height: 41px;
  color: #2D4158;
  font-weight: bold;
}

.homeNavInnerSection .themeSection p {
  font-size: 18px;
  line-height: 25px;
  color: #707894;
}

.fadeInLeft {
  animation-name: fadeInLeft;
}


@keyframes fadeInLeft {
  0% {
      opacity: 0;
      transform: translate3d(-100%, 0, 0);
  }

  100% {
      opacity: 1;
      transform: none;
  }
}

.homeNavInnerSection .happyCustomers h3 {
  font-size: 38px;
  text-align: center;
  line-height: 52px;
  color: #2D4158;
  font-weight: bold;
}

.homeNavInnerSection .happyCustomers p {
  font-size: 16px;
  text-align: center;
  line-height: 28px;
  color: #707894;
  margin-bottom: 65px;
}

.homeNavInnerSection .profileInsWrap .profileInstructions img {
  display: block;
  padding: 4px;
  margin-bottom: 20px;
  line-height: 1.42857143;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  height: 80px;
  object-fit: cover;
}

.homeNavInnerSection .profileInsWrap {
  padding: 20px 15px;
  border-radius: 0px;
  background: white;
  color: #2d4158;
  box-shadow: 0 12px 42px 12px rgba(0, 0, 0, 0.08);
  height: 336px;
  margin-bottom: 15px;
}

.homeNavInnerSection .profileInsWrap .profileInstructions {

  display: flex;
  flex-direction: column;
  align-items: center;
}

.homeNavInnerSection .profileInsWrap .profileInstructions h3 {
  font-size: 20px;
  line-height: 27px;
  color: #000000;
  margin-bottom: 0;
  font-weight: bold;
}

.homeNavInnerSection .profileInsWrap .profileInstructions p {
  font-size: 12px;
  line-height: 17px;
  color: #969695;
  margin-bottom: 15px;
  text-transform: uppercase;
}

.homeNavInnerSection .profileInsWrap .profileInstructions span {
  font-size: 16px;
  text-align: center;
  line-height: 25px;
  color: #707894;
}

.homeNavInnerSection .partnerDistricts {
  text-align: center;
}

.homeNavInnerSection .partnerDistricts h3 {
  font-size: 23px;
  line-height: 32px;
  color: #8990AC;
  font-weight: 400;
  margin-top: 40px;
}

.homeNavInnerSection .partnerDistricts img {
  width: 180px;
}

.homeNavInnerSection .appLinksWrap .availabletext {
  font-size: 38px;
  font-weight: 600;
  line-height: 52px;
  color: #000000;
  text-transform: uppercase;
}

.homeNavInnerSection .appLinksWrap .appLinks {
  display: flex;
  gap: 15px;
}

.homeNavInnerSection .appLinksWrap .appLinks img {
  width: 150px;
}

.homeNavInnerSection .appLinksWrap .appLinks span:last-child img {
  opacity: 0.6;
}

.homeNavInnerSection .appLinksWrap .appLinks span strong {
  font-size: 12px;
  text-transform: uppercase;
}

.homeNavInnerSection .appLinksWrap .appLinks span {
  color: #999;
}

.homeNavInnerSection .appLinksWrap .appLinks span:last-child {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.homeNavInnerSection .appLinksWrap {
  margin-top: 154px;
}

.lastfooter .socialLinksWrap span.twitter:hover svg,
.lastfooter .socialLinksWrap span.facebook:hover svg,
.lastfooter .socialLinksWrap span.linkedin:hover svg {
  color: #4784b1 !important;
}

.lastfooter .socialLinksWrap {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  cursor: pointer;
}

.lastfooter .socialLinksWrap .facebook svg {
  content: "\f39e";
  font-size: 31px;
  color: white;
}

.lastfooter .socialLinksWrap .twitter svg {
  content: "\f099";
  font-size: 30px;
  color: white;
}

.lastfooter .socialLinksWrap .linkedin svg {
  content: "\f0e1";
  color: white;
  font-size: 30px;
}

ol.carousel-indicators.active {
  top: 50% !important;
  width: 20px;
  right: 15px !important;
  left: auto !important;
}

.carousel-indicators {
  position: sticky;
  top: 150px;
  display: flex;
  flex-direction: column;
  width: 100px;
  float: right;
  z-index: 1;
  margin-top: 100px;
  margin-bottom: 150px;
  padding-left: 30px;
}

.carousel-indicators li {
  padding: 8px;
  background: #c1c1c1;
}

.indicator1,
.indicator2,
.indicator3 {
  background-color: #6f60ae !important;
  border: 1px solid #6f60ae !important;
}

.featuresli {
  color: red;
}
    /* footer start  */
    .firstfooter {
      background: #fff;
      padding: 50px 0;
      width: 100%;
      box-shadow: 0 0 8px -3px #888888;
  }

  .lastfooter {
      background-color: #271F43 !important;
  }

  .lastfooter ul li a {
      font-size: 14px;
      font-weight: 300;
      line-height: 34px;
      color: #b7b1b1;
      opacity: 0.8;
      text-transform: uppercase;
      text-decoration: none;
  }

  .lastfooter ul:first-child {
      padding: 0;
  }
  .lastfooter .socialLinksWrap span.twitter:hover::before,
  .lastfooter .socialLinksWrap span.facebook:hover::before,
  .lastfooter .socialLinksWrap span.linkedin:hover::before {
      color: #4784b1 !important;
  }

  /* .lastfooter .socialLinksWrap .facebook:before {
      content: "\f39e";
      font-size: 31px;
      color: white;
  } */

  /* .lastfooter .socialLinksWrap .twitter:before {
      content: "\f099";
      font-size: 30px;
      color: white;
  } */

  /* .lastfooter .socialLinksWrap .linkedin:before {
      content: "\f0e1";
      color: white;
      font-size: 30px;
  } */

  .lastfooter .lastrow span {
      font-size: 13px;
      font-weight: 300;
      color: #fff;
      opacity: 0.8;
  }

  /* footer end  */



  /* About page publicpages  */

  .homeAboutPage .infoAboutUs h3 {
    font-size: 38px;
    text-align: center;
    line-height: 52px;
    color: #2D4158;
    margin-bottom: 20px;
    text-transform: uppercase;
    font-weight: bold;
}

.homeAboutPage .infoAboutUs p {
    font-size: 16px;
    text-align: left;
    line-height: 28px;
    color: #707894;
    margin-bottom: 24px;
}

.homeAboutPage .card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 350px;
    margin: 50px auto 0;
    box-shadow: 0px 0px 10px 0px #dedede;
    border-radius: 2px;
    height: 530px;
}

.homeAboutPage .card:hover {
    box-shadow: 0px 0px 30px 0px #dedede;
}

.homeAboutPage .card h2 {
    font-size: 20px;
    line-height: 27px;
    color: #000000;
    text-transform: uppercase;
}

.homeAboutPage .card h6 {
    font-size: 12px;
    line-height: 17px;
    color: #969695;
    margin-bottom: 15px;
    text-transform: uppercase;
}

.homeAboutPage .card p {
    padding: 15px 20px;
    font-size: 15px;
    text-align: left;
    color: #707894;
    line-height: 1.6;
}

.homeAboutPage .memberimg1 {
    display: inline-block;
    width: 140px;
    height: 140px;
    border-radius: 100%;
    overflow: hidden;
    margin: 40px auto 30px;
    background: url(/src/assets/imgs/monicapublicpages.jpg) no-repeat top center;
    background-size: 140px;
    background-position: 0px -18px;
}

.homeAboutPage .memberimg2 {
    display: inline-block;
    width: 140px;
    height: 140px;
    border-radius: 100%;
    overflow: hidden;
    margin: -39px auto 30px;
    background: url(/src//assets/imgs/bradpublicpages.jpg) no-repeat top center;
    background-size: 140px;
    background-position: 0px -18px;
}

.homeAboutPage .memberimg3 {
    display: inline-block;
    width: 140px;
    height: 140px;
    border-radius: 100%;
    overflow: hidden;
    margin: 5px auto 30px;
    background: url(/src/assets/imgs/chanderpublicpages.jpg) no-repeat top center;
    background-size: 140px;
    background-position: 0px -18px;
}

.supportPage{
  padding-top: 30px;
}
.supportPage address {
  margin-bottom: 2em
}

.supportPage address p {
  font-size: 16px;
  line-height: 22px;
  color: #53565B;
  font-weight: 400;
}
.supportPage .mapSection{
  margin-top: 20px;
}
.supportPage #map{
  height: 500px;
}

.homeNav .navbar-right li a.active{
  color: #ffc80a;
}

.scheduleForm .react-datetime-picker.react-datetime-picker--open.react-datetime-picker--enabled,
.scheduleForm .react-datetime-picker.react-datetime-picker--closed.react-datetime-picker--enabled {
  width: 100%;
  height: 38px;
}
.scheduleForm .react-datetime-picker__wrapper {
  border: thin solid #cdcdcd !important;
  background-color: white;
  padding: 3px 9px;
}

.scheduleForm .menu-duration{
  padding: 0px 0px 0px 15px;
  width: 97%;
}
.scheduleForm textarea {
  border: 1px solid #d1d1d1;
  width: 100%;
  border-radius: 4px;
  resize: none;
  padding: 0px 10px;
  height: 118px;
}
.scheduleForm input[type="text"]::placeholder,
.scheduleForm textarea::placeholder {
  font-size: 14px;
  color: rgb(149, 145, 145);
}
@media screen and (max-width: 1200px) {
  .scheduleForm .meetingtitle {
    margin-top: 15px;
  }
  .scheduleForm .duration {
    margin-top: 15px;
  }
}


.react-tooltip{
  border: 1px solid #d1d1d1 !important;
}
.scheduleForm
  button.react-datetime-picker__clear-button.react-datetime-picker__button {
  display: none;
}

.meetingIndex .liveMeetingOuterWrap{
  display: flex;
  flex-wrap: wrap;
  margin: 0px;
  margin-bottom: 20px;
}
.meetingIndex .tableOuterWrap {
  overflow: auto;
}
.meetingIndex table thead tr th{
  border: 1px solid white;
}
.meetingIndex table thead tr{
  border: 1px solid #e3e3e3;
}
/* .meetingIndex .tableOuterWrap::-webkit-scrollbar-thumb {
  background: #cbc5cd;
}
.meetingIndex .tableOuterWrap::-webkit-scrollbar,
.meetingIndex .tableOuterWrap::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  border-radius: 50px;
} */
.quesPreTest table .handleTooltip .react-tooltip{
  left: 147px !important;
}



/* validation errro */
.scheduleForm #error {
  color: #cd3939;
  height: 20px;
  display: inline-block;
  position: absolute;
  width: 100%;
  left: 17px;
  font-size: 13px;
}
.sidebar-menu svg path{
  stroke: white !important;
}

@import url(./opensans.css);
.onGoingMeeting, .onGoingMeeting *{font-family: "Open Sans", sans-serif;}
.onGoingMeeting {
  display: flex;
  gap: 13px;
  flex-wrap: wrap;
  padding-top: 20px;
  padding-bottom: 20px;
  /* padding-right: 6px; */
  position: relative;
}
.onGoingMeeting::-webkit-scrollbar {
  height: 4px;
  width: 4px;
  background: #dfdfdf;
}
.onGoingMeeting::-webkit-scrollbar-thumb {
  background: #cfcaca;
}
.onGoingMeeting .meetingList{
  display: flex;
  flex-direction: column;
  padding:10px;
  height: 96px;
  border-left: #4078ba solid 3px;
  align-items: flex-start;
  font-size: 11px;
  color: #555;
  position: relative;
  width: calc(100% / 4 - 10px);
  background-color: #fff;
  box-shadow: 0px 0 5px 0px #0000001f;
  border-radius: 10px;
}
.onGoingMeeting .meetingList .title{
  font-size: 15px;
  font-weight: 600;
}
.onGoingMeeting .meetingList .className{
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 2px 8px;
  background-color: #4078ba;
  color: white;
  font-size: 10px;
  border-radius: 10px;
  margin-top: 5px;
  font-weight: 500;
  white-space: nowrap;
}
.onGoingMeeting .meetingList > a{
  width: 26px;
  height: 26px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  border: #4078ba solid 2px;
  border-radius: 100%;
  color: #4078ba;
  font-size: 12px;
  right: 14px;
  top: 0;
  bottom: 0;
  margin: auto;
}
.onGoingMeeting .meetingList:hover{
  background-color: #4078ba;
  color: white;
}
.onGoingMeeting .meetingList:hover > a{
  border-color: #fff;
  color: #fff;
}
.onGoingMeeting .meetingList:hover .className{
  color: #4078ba;
  background-color: white;
}

/* action dropdown */
.meetingIndex td .dropdown .dropdown-menu {
  left: -167px;
  width: 100%;
  top: 2px;
  border-top-right-radius: 0px;
  padding: 8px 10px;
}
.meetingIndex td .dropdown .dropdown-menu .dropdown-item {
  color: #000;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 13px;
}
.meetingIndex td .dropdown .dropdown-menu .dropdown-item:hover{
  text-decoration: none !important;
}
.meetingIndex td .dropdown .dropdown-menu:before {
  border-left: 10px solid #b3b3b3;
  position: absolute;
  content: "";
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  right: -10px;
  top: -1px;
}

.meetingIndex .totalData{
  background: #3177c9;
  font-size: 12px;
  color: white;
  /* border-radius: 100%; */
  font-weight: bold;
  /* height: 23px;
  width: 23px;
  display: flex;
  justify-content: center;
  align-items: center; */
}
.scheduleForm #student .css-13cymwt-control {
  height: 38px !important;
  overflow: auto !important;
}
.selectforDistrict .css-13cymwt-control {
  width: 534px !important;
}
.selectforDistrict .form-select-control.applyFilterSelect2.css-b62m3t-container {
  width: 534px !important;
  display: block;
}
.meetingIndex .btn-primary:hover {
  color: #fff !important;
  background-color: #02857a !important;
  border-color: #02857a !important;
}
.meetingIndex{
  padding-top: 70px;
    width: calc(100% - 153px);
    display: flex;
    flex-direction: column;
    height: 100vh;
    margin-left: auto;
    padding-right: 24px;
}
.scheduleForm .css-13cymwt-control,
.scheduleForm .react-date-picker__wrapper {
  height: 38px;
  border: 1px solid rgb(209, 209, 209) !important;
}
.scheduleForm .form-select-control.firstClass.css-3iigni-container {
  height: 38px;
}
.scheduleForm .datetimepicker .react-date-picker.react-date-picker--closed.react-date-picker--enabled,
.scheduleForm .datetimepicker .css-b62m3t-container{
  width: 50% !important;
}
.rs-picker-popup {
  z-index: 9999 !important;
}

.scheduleForm .studentFilter .dropdown-toggle{
  position: relative;
  width: 100%;
  text-align: left;
  border: none;
  background: white;
  height: 35px;
}
.scheduleForm .studentFilter .dropdown-toggle:after {
  position: absolute;
  content: "";
  height: 66%;
  width: 1px;
  right: 35px;
  background: rgb(193 192 192);
  top: 6px;
}
.scheduleForm .studentFilter .studentFilterDropdownMenu{
  height: 143px;
  overflow: auto;
  width: 100%;
  padding-top: 0px;
  margin: 0px;
}
.scheduleForm .studentFilter .studentFilterDropdownMenu .searchStudents input{
    /* width: 74%;
    height: 36px;
    position: relative;
    top: 6px;
    margin-left: 19px; */
    width: 96%; 
    margin: auto; 
    height: 36px; 
    position: 
    relative; 
    top: 6px;
}
.scheduleForm .studentFilter .studentFilterDropdownMenu .searchStudents{
  height: 47px;
}
.scheduleForm .studentFilter .studentFilterDropdownMenu li label{
  font-size: 15px;
}
.scheduleForm .studentFilter .studentFilterDropdownMenu li label input[type='checkbox']{
  position: relative;
  top: 1px;
  margin-right: 8px;
}
.scheduleForm .studentFilter .dropdown-toggle svg{
  position: absolute; 
  right: 9px;
  color: rgb(193 192 192);
}
.scheduleForm .studentFilter .studentFilterDropdownMenu .newli:first-child {
  position: sticky;
  top: 0px;
  background: white;
  z-index: 9;
}
.parentUserTable tr td span{
  max-width: 107px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.innerMeetingDetailsWrap{
  display: flex;
  gap: 30px;
  height: calc(100vh - 140px);
  overflow: hidden;
}
.innerMeetingDetailsWrap .meetingDetails{
  display: flex;
  position: relative;
  background: #c6ddf7;
  padding: 13px 20px 13px 13px;
  flex-direction: column;
  border-radius: 10px;
  gap: 7px;
  border-bottom-right-radius: 0 !important;
}
.innerMeetingDetailsWrap .tableOuterWrap{
  height: calc(100% - 187px);
  overflow: auto;
  background-color: #fff;
}

/* .innerMeetingDetailsWrap .tableOuterWrap::-webkit-scrollbar {
  height: 4px;
  width: 4px;
  background: #e7e3e3;
} */
/* .innerMeetingDetailsWrap .tableOuterWrap::-webkit-scrollbar-thumb {
  background: #cfcaca;
} */
.innerMeetingDetailsWrap .tableOuterWrap table thead th,
.innerMeetingDetailsWrap .tableOuterWrap table thead td{
  padding: 8px !important;
}
.innerMeetingDetailsWrap .tableOuterWrap table tr:last-child{
  background: #fff !important;
}
.innerMeetingDetailsWrap .tableOuterWrap table tr:last-child td{
  border-bottom: none !important;
}
.innerMeetingDetailsWrap .meetingDetails .appendedDescription{
  font-size: 13px;
  color: #3177c9;
  max-width: 511px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

}
.innerMeetingDetailsWrap .meetingDetails .description{
  position: absolute;
  right: 9px;
  display: flex;
  top: 41px;
  background: white;
  border: 1px solid #3177c9;
  color: #3177c9;
  font-weight: lighter;
  box-shadow: 0px 0px 6px 1px #3177c9;
  cursor: pointer;
}
.innerMeetingDetailsWrap .meetingDetails .topic{
  color: #3177c9;
  font-size: 17px;
  /* margin-bottom: 7px; */
  font-weight: bold;
}
.innerMeetingDetailsWrap .meetingDetails .teacherName,
.innerMeetingDetailsWrap .meetingDetails .studentSelected{
  display: flex;
  align-items: center;
  font-size: 13px;
  color: #3177c9;
  gap: 4px;
}
.innerMeetingDetailsWrap .meetingDetails .studentSelected svg{
  font-size: 14px;
}
.innerMeetingDetailsWrap .meetingDetails .teacherName svg{
  position: relative;
  top: -1px;
  color: #3177c9;
}
.innerMeetingDetailsWrap .pastMeetingData{
  display: flex;
  align-items: center;
  padding: 13px 20px 13px 13px;
  border-radius: 10px;
  border-top-right-radius: 0 !important;
  border-top-left-radius: 0 !important;
  background-color: white;
  margin-bottom: 20px
}
.innerMeetingDetailsWrap .pastMeetingData svg{
  height: 16px;
}
.innerMeetingDetailsWrap .pastMeetingData .videoIcon {
  display: inline-block;
  width: 50px;
}
.innerMeetingDetailsWrap .pastMeetingData .videoIcon .icon{
  width: 35px;
  height: 32px;
  border-radius: 10px;
  background: #c6ddf7;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #3177c9;
}
.innerMeetingDetailsWrap .pastMeetingData .meetingId{
  font-size: 13px;
  font-weight: bold;
  display: inline-block;
}
.innerMeetingDetailsWrap .pastMeetingData .dot{
  display: inline-block;
  height: 7px;
  width: 7px;
  border-radius: 100%;
  background: #02857a;
  margin: 0 10px;
}
.innerMeetingDetailsWrap .pastMeetingData .classDuration{
  color: #02857a;
  font-size: 13px;
  display: inline-block;
  font-weight: bold;
}
.innerMeetingDetailsWrap .pastMeetingData .startDate,
.innerMeetingDetailsWrap .pastMeetingData .endDate{
  display: block;
  font-size: 13px;
  color: #6e6e6e;
  font-weight: bold;
}
.innerMeetingDetailsWrap .pastMeetingData .timeWrap{
  display: flex;
  align-items: baseline;
}
.innerMeetingDetailsWrap .pastMeetingData .timeWrap .dot{
  background-color: #6e6e6e;
}
.innerMeetingDetailsWrap .dataCount{
  display: flex;
  align-items: center;
  gap: 10px;
  background: #02857a;
  border: 2px solid #02857a;
  padding: 3px 13px 3px 13px !important;
  border-radius: 5px;
  margin-bottom: 2px;
}
.innerMeetingDetailsWrap .dataCount .datainnerCount{
 color: #02857a !important; 
}
.innerMeetingDetailsWrap .VideoRecordingsWrap{
  width: 100%;
  background: #c6ddf7;
  padding: 13px 20px 13px 13px;
  border-radius: 10px;
  height: 100%;
}

.meetingDetails .topic .react-loading-skeleton {
    background: white;
    height: 10px;
    width: 60%;
}
.meetingDetails .teacherName .react-loading-skeleton {
  width: 25%;
  height: 10px;
  display: inline-block;
  background: white;
  position: absolute;
  bottom: 43px;
  left: 33px;
}
.meetingDetails .studentSelected .react-loading-skeleton {
  position: absolute;
  width: 18%;
  background: white;
  height: 10px;
  bottom: 17px;
  left: 34px;
}
.innerMeetingDetailsWrap .meetingId .react-loading-skeleton {
  width: 100px;
  position: absolute;
  height: 10px;
  margin-left: 24px;
  margin-top: 4px;
}
.timeWrap .endDate .react-loading-skeleton {
  width: 100px;
  position: absolute;
  height: 10px;
  margin-top: 3px;
}
.innerMeetingDetailsWrap .description .react-loading-skeleton {
  width: 60px;
}
.VideoRecordingsWrap .react-loading-skeleton {
  height: 95%;
  background: white;
  border-radius: 15px;
}

.VideoRecordingsWrap iframe{
  border-radius: 13px;
}

.nestedTableWrap .table-responsive{
  max-height: 159px !important;
}
.nestedTableWrap  .timeReport{
  font-size: 22px;
  font-weight: 500;
  line-height: 25px;
  color: #60676a;
  margin: 0;
  text-align: center;
  margin-bottom: 20px;
}
.tdsvgWrap{
  display: flex;
  gap: 5px;
}
.tdsvgWrap span{
  height: 25px;
  width: 28px;
  border: 1px solid;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background: #3177c9;
  color: white;
}

.responsive-table::-webkit-scrollbar-thumb,
.tableOuterWrap::-webkit-scrollbar-thumb,
.table-responsive::-webkit-scrollbar-thumb {
  background: #cbc5cd;
}
.responsive-table::-webkit-scrollbar,
.tableOuterWrap::-webkit-scrollbar,
.table-responsive::-webkit-scrollbar{
  width: 4px;
  height: 4px;
  border-radius: 50px;
}
.meetingTooltip{
  width: 100px;
  z-index: 999 !important;
  background-color: black !important;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  border-radius: 9px;
}
.react-tooltip-arrow{
  background: black !important;
  color: black !important;
}
.react-date-picker__inputGroup{
  padding-left: 10px !important;
}
.removeFilterButton{
  padding: 0 !important;
  min-width: 50px;
  max-width: 50px;
  font-size: 24px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.removeFilterButton:hover svg{
  color: white;
}

svg.react-date-picker__clear-button__icon.react-date-picker__button__icon{
  display: none;
}
.meetingIndex .react-date-picker.react-date-picker--closed.react-date-picker--enabled,
.meetingIndex .react-date-picker.react-date-picker--open.react-date-picker--enabled {
  min-width: 226px;
  max-width: 226px;
  border: 1px solid #bbb;
  position: absolute;
  right: 0;
  top: 82%;
  border-radius: 4px;
  background: #fdfeff;
  font-weight: 600;
}
.meetingIndex .react-date-picker__calendar.react-date-picker__calendar--open {
  top: 32px !important;
  left: -125px !important;
}
.meetingIndex button.react-date-picker__clear-button.react-date-picker__button{
  margin: 0 !important;
}
#regAsParent span#error {
  position: absolute;
  font-size: 12px;
}
.css-1jqq78o-placeholder:not(:nth-child(2)){
  color: #6e6e6e !important;
}
.classStudent .selectBoxOptionStudents .css-1jqq78o-placeholder{
  color: #6e6e6e !important;
}
#studentClsReport .css-1jqq78o-placeholder{
  color: #6e6e6e !important;
}

.scheduleForm .breadcrum li:first-child{
  color: #0f6ed9 !important;
}
.scheduleForm .breadcrum li:nth-child(2){
  color: #707070 !important;
}
.breadcrumb>.active {
  color: #707070;
}
.loginWithClever{
  position: relative;
  background-color: #4070ee;
  border: 1px solid #4070ee;
  color: white;
  padding: 15px 80px;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  font-weight: bold;
  box-shadow: 0px 8px 12px -1px black;
  /* right: -20px; */
  width: 340px;
}
.loginWithClever:after {
  content: "";
  position: absolute;
  background: #d5d5d5;
  height: 37px;
  width: 1px;
  top: 7px;
  left: 58px;
}
.cleverName{
  position: absolute;
  z-index: 9;
  color: white;
  font-weight: bold;
  font-size: 39px;
  margin-left: 20px;
}

.otpVerifyWrap{
  padding: 20px;
  background-color: #fff;
}
.otpVerifyWrap h3{
  color: #4c4c4c;
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 15px;
}
.otpVerifyWrap span:first-child{
  font-size: 16px;
  color: #525050;
}
.otpVerifyWrap span:not(:first-child){
  font-size: 16px;
  color: #b9b4b4;
  font-weight: 600;
}
.otpVerifyWrap .otpInputs{
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}
.otpVerifyWrap .otpInputs input{
  border: 1px solid #bbb;
  border-radius: 5px;
  width: calc(16.6666666667% - 22px) !important;
  padding: 10px 11px;
  text-align: center;
  font-size: 19px;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
.otpVerifyWrap a{
  color: #4c4c4c;
  font-weight: bold;
  font-size: 17px;
}
.otpVerifyWrap .buttonWrap{
  display: flex;
  justify-content: flex-end;
}
.otpVerifyWrap .buttonWrap button:disabled{
  padding: 11px 21px;
  background: #dbdbdb;
  border: 1px solid #dbdbdb;
  border-radius: 6px;
  color: #707070;
}
.otpVerifyWrap .buttonWrap button{
  padding: 11px 21px;
  background: #8e44ad;
  border: 1px solid #8e44ad;
  border-radius: 6px;
  color: #fff;
}

.checkonBtnWrap{
  display: flex;
  justify-content: flex-end;
}
.checkonBtnWrap span label{
  padding-left: 7px;
  font-size: 15px;
}
.checkonBtnWrap span{
  display: flex;
}
.checkonBtnWrap span input{
  margin: 0;
  position: relative;
  top: -2px;
}
.otpVerifyWrap .timeRemaining span{
  font-weight: lighter;
  font-size: 15px;
}
.otpVerifyWrap .timeRemaining span:first-child{
  display: flex;
  gap: 10px;
}
/* General modal styles */
.pretesQuestionScratchPadData .modal-dialog {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 1rem);
}

.pretesQuestionScratchPadData .modal-content {
  background-color: #fff;
  border: none;
  border-radius: 0.3rem;
  box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
  margin: 1.75rem auto;
  max-width: 600px;
  width: 100%;
}

.pretesQuestionScratchPadData .modal-body {
  padding: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pretesQuestionScratchPadData .modal-body img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}


/* UploadQuestion css */

.uploadQuesWrap{
  padding-top: 70px;
  width: calc(100% - 160px);
  display: flex;
  flex-direction: column;
  height: 100vh;
  margin-left: auto;
  padding-right: 24px;
}
.uploadQuesWrap .innerUploadQuesWrap{
  background-color: white;
  margin-top: 20px;
}
.uploadQuesWrap .innerUploadQuesWrap .buttonsArea{
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  padding: 10px 20px 1px 16px;
  align-items: center;
}
.uploadQuesWrap .innerUploadQuesWrap .buttonsArea .nextBtn{
  border-left: 1px solid black;
}
.uploadQuesWrap .innerUploadQuesWrap .buttonsArea .nextBtn svg{
  font-size: 29px;
}
.uploadQuesWrap .innerUploadQuesWrap .buttonsArea button{
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 9px 26px;
  border-radius: 3px;
  color: white;
}
.uploadQuesWrap .innerUploadQuesWrap .buttonsArea button:first-child{
  border: 1px solid #02857a;
  background-color: #02857a;
}
.uploadQuesWrap .innerUploadQuesWrap .buttonsArea button:last-of-type{
  border: 1px solid #e72740;
  background-color: #db4a55;
}
.uploadQuesWrap .innerUploadQuesWrap .questionLine {
  display: flex;
  gap: 10px;
}
.uploadQuesWrap .innerUploadQuesWrap .optionsWrap{
  display: flex;
  gap: 20px;
  word-break: break-all;
  padding-top: 20px;
}
.uploadQuesWrap .innerUploadQuesWrap .correctAnswerWrap,
.uploadQuesWrap .innerUploadQuesWrap .lctWrap,
.uploadQuesWrap .innerUploadQuesWrap .hintWrap,
.uploadQuesWrap .innerUploadQuesWrap .uploadImgWrap,
.uploadQuesWrap .innerUploadQuesWrap .speaker{
  padding-top: 20px;
}
.uploadQuesWrap .innerUploadQuesWrap .speaker{
  display: flex;
}
.uploadQuesWrap .innerUploadQuesWrap .correctAnswerWrap .correctAnswer,
.uploadQuesWrap .innerUploadQuesWrap .hintWrap .hint,
.uploadQuesWrap .innerUploadQuesWrap .lctWrap .lct{
  font-weight: bold;
}
.uploadQuesWrap .innerUploadQuesWrap .optionsWrap .optNo,
.uploadQuesWrap .innerUploadQuesWrap .questionSign {
  font-weight: bold;
  font-size: 17px;
}

.scheduleForm .css-t3ipsp-control{
  border: none !important;
}
.uploadQuesWrap .innerUploadQuesWrap > .row{
  margin: 0;
}
.uploadExcelFileWrap{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
}
.uploadExcelFileWrap .uploadExcelFile{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 12px;
  border: 3px dashed #e9e9e9;
  border-radius: 9px;
  padding: 18px 74px;
}
.uploadExcelFileWrap .uploadExcelFile .uploadExcelsvg path{
  stroke: #02857a !important;
}
.uploadExcelFileWrap .uploadExcelFile .dropaFile{
  color: #c7c7c7;
  margin: 0;
}

.blurred {
  filter: blur(1.2px);
  opacity: 2;
  pointer-events: none;
}

/* .addStrandsWrap .removeTopicBtn{
  position: absolute;
  right: 0px;
  bottom: 1px;
  background: #02857a;
  color: white;
  padding: 12px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.addStrandsWrap .addStrandsForm{
  margin: auto;
}
.addTopicBtn:hover{
  color: white !important; 
} */
.optionsWrap img {
  height: 200px;
  width: 100px;
}
.lotusSidebar {
  position: fixed;
  width: 130px;
  height: 100%;
  left: 0px;
  background: #454562 !important;
  transition: left 0.4s ease;
  overflow: auto;
}

nav.lotusSidebar  ul {
  width: 100%;
  list-style: none;
}
nav.lotusSidebar > ul{
padding-top: 70px;
}
nav.lotusSidebar ul.unAssign-show li a,
nav.lotusSidebar ul.reports-show li a,
nav.lotusSidebar ul.userStatus-show li a{
  padding-left: 0px;
  font-size: 10px;
}
nav.lotusSidebar ul li {
  position: relative;
  line-height: 60px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

nav.lotusSidebar ul li:last-child {
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}

nav.lotusSidebar ul li a {
display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 4;
  padding-top: 10px;
  position: relative;
  color: white;
  text-decoration: none;
  font-size: 12px;
  /* padding-left: 6px;  */
  font-weight: 500;
  width: 100%;
  border-left: 3px solid transparent;
}
nav.lotusSidebar ul li.active {
border-left: 3px solid white;
}
nav.lotusSidebar ul li ul li.active {
border-left: none;
}

nav.lotusSidebar ul li a span.first,
nav.lotusSidebar ul ul li a span.sixth{
  font-size: 12px;
  position: absolute;
  right: 6px;
  height: 10px;
  display: flex;
}

/* nav.lotusSidebar ul li.active a {
  border-left-color: white;
} */


nav.lotusSidebar ul ul {
  position: static;
  display: none;
}

nav.lotusSidebar ul .reports-show.show,
nav.lotusSidebar ul .unAssign-show.show1,
nav.lotusSidebar ul .userStatus-show.show2,
nav.lotusSidebar ul ul.show3,
nav.lotusSidebar ul ul.show4,
nav.lotusSidebar ul ul.show5{
  display: block;
}
.reports-show.show li a,
.unAssign-show.show1 li a,
.userStatus-show.show2 li a{
background-color: #55556f !important;
}
.teacherMenu-show.show3 li a,
.studentActivityMenu-show.show4 li a,
.studentSessionMenu-show.show5 li a{
border-bottom: 1px solid white !important;
}
nav.lotusSidebar ul ul li {
  line-height: 42px;
  border-top: none;
}

nav.lotusSidebar ul ul li a {
  font-size: 17px;
  color: #e6e6e6;
  padding-left: 80px;
}

nav.lotusSidebar ul li.active ul li a {
  color: #e6e6e6;
  background: #454562;
  border-left-color: transparent;
  padding-left: 6px;
  font-size: 10px;
  position: relative;
}

nav.lotusSidebar ul li a span {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  font-size: 22px;
  transition: transform 0.4s;
}

nav.lotusSidebar ul li a span.rotate {
  transform: translateY(-50%) rotate(-180deg);
}
nav.lotusSidebar ul li.academicStrandsMenu{
height: 70px;
line-height: 18px;
display: flex;
align-items: center;
}


/* addStrands */
.addStrandsWrap{
  height: calc(100vh - 85px);
  background: white;
  display: flex;
  justify-content: center;
  padding-top: 20px;
}
.addStrandsWrap form.addStrandsForm{
  width: 100%;
  box-shadow: 5px 4px 17px #cdcdcd;
  max-height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-top: 0 !important;
}
.addStrandsWrap form.addStrandsForm > .row > .col{
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 9;
  padding-bottom: 20px;
}
.addStrandsWrap form.addStrandsForm > .row > .col > label{
  margin-top: 14px;
}
.addStrandsWrap form.addStrandsForm .inputTrashBtnWrap{
  display: flex;
  gap: 11px;
  align-items: center;
}
.addStrandsWrap form.addStrandsForm .addTopicsBtn,
.addStrandsWrap .removeTopicBtn{
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
}
.addStrandsWrap form.addStrandsForm .addTopicsBtn svg,
.addStrandsWrap form.addStrandsForm .removeTopicBtn svg{
  font-size: 19px;
  margin-top: -2px;
}
.addStrandsWrap form.addStrandsForm .removeTopicBtn svg{
  width: 37px;
}
.addStrandsWrap .removeTopicBtn{
  gap: 6px;
  margin-top: 1px;
  background: #ff5151 !important;
    box-shadow: 3px 1px 9px #f93030 !important;
}
.addStrandsWrap .removeTopicBtn:hover{
  color: white !important;
}
.addStrandsWrap form.addStrandsForm .addTopicsBtn:hover{
  background-color: #02857a !important;
}
.addStrandsWrap > .classroom-info{
  max-width: 790px !important;
}
li.submenuLiIsActive:before {
  position: absolute;
  content: "";
  background: white;
  height: 100%;
  width: 3px;
  left: 0;
}

.classroomDetailedRep{
  margin-top: 110px;
  width: calc(100% - 129px);
  margin-left: auto;
  padding-right: 16px;
}
.filtersProficiencyWrap{
  display: flex;
  padding-right: 0;
  justify-content: flex-end;
}
.filtersProficiencyWrap .filtersProficiency {
  display: flex;
  justify-content: flex-end;
}
.filtersSelect{
  padding-left: 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
  gap: 15px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 0;
}
div#reportsFilters {
  width: 200px;
}
.classRepDetailedWisefiltersRighSide{
  display: flex;
  gap: 32px;
}

/* Loader container */
.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Loader styles */
.loader {
  border: 4px solid rgba(0, 0, 0, 0.1); /* Light gray border */
  border-left: 4px solid #4caf50; /* Green border for spinning effect */
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
}

.badge-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.subjectGradeData {
  text-wrap: wrap;
  min-width: 300px;
}

.districtData{
  text-align: center;
}

.subject-grade__control {
  max-height: 115px;
  overflow-y: auto;
}

.subject-grade__menu {
  max-height: 150px;
  overflow-y: auto !important;
}

.subject-grade__menu-list {
  max-height: 150px;
  overflow-y: visible !important; 
}
div#studentDatePicker{
  width: 100% !important;
}
.attendanceDatePicker {
  border: 1px solid hsl(0, 0%, 80%);
  border-radius: 4px;
  min-height: 38px;
  padding: 0px 13px;
}

.attendanceDatePicker .react-calendar {
  position: absolute !important;
  bottom: 0 !important;
  margin-left: 0px !important;
}

/* style by gourav jaat */
#attendanceDatePicker{
  background-color: #fff;
  width: 100% !important;
}
.attendanceReportDatePicker {
  border: 1px solid hsl(0, 0%, 80%);
  border-radius: 4px;
  min-height: 38px;
  padding: 0px 13px;
}

.attendanceReportDatePicker .react-calendar {
  position: absolute !important;
  /* bottom: 0 !important; */
  margin-left: 0px !important;
}

.attendanceForm .studentFilter .studentFilterDropdownMenu .searchStudents input{
    width: 96%; 
    margin: auto; 
    height: 36px; 
    position: 
    relative; 
    top: 6px;
}
.attendanceForm .studentFilter .studentFilterDropdownMenu .searchStudents{
  height: 47px;
}
.attendanceForm .studentFilter .studentFilterDropdownMenu li label{
  font-size: 15px;
}
.attendanceForm .studentFilter .studentFilterDropdownMenu li label input[type='checkbox']{
  position: relative;
  top: 1px;
  margin-right: 8px;
}

.attendanceForm .studentFilter .studentFilterDropdownMenu .newli:first-child {
  position: sticky;
  top: 0px;
  background: white;
  z-index: 9;
}
.feedBackSelect.studentFilter button{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 10px;
  color: #cccccc;
}
.feedBackSelect.studentFilter svg{
  border-left: 1px solid #cccccc;
  padding-left: 5px;
}
input.enterSectionName{
  border: 1px solid #ccc;
  border-radius: 3px;
}
input.enterSectionName::placeholder {
  font-size: 15px;
}

.CreateClsRoomWrap .createClassRoom #makred{
  width: 50%;
}
.rs-picker-error > .rs-picker-input-group {
  border-color: hsl(0, 0%, 70%) !important;
}

.badge-inactive {
  background-color: #e42448; 
}

.badge-active {
  background-color: #00884b;
}
