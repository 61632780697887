/******** error msg css start ***********/
span#error {
  color: #cd3939;
}
/******** error msg css end ***********/
/******************loading spinner css start ***************/

.loader {
  /* border: 3px solid #f3f3f3;  */
  border-radius: 50%;
  border-top: 3px solid #fff;
  border-left: 3px solid #fff;
  width: 22px;
  height: 22px;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
  margin: auto;
  margin-top: 5px;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/******************loading spinner css end ***************/

/********* 500 page error style  **********/

@font-face {
  font-family: "kg_second_chances_sketchRg";
  src: url("./assets/webfonts/kgsecondchancessketch-webfont.woff2");
  font-weight: normal;
  font-style: normal;
}

.errorserver-block {
  background: url(./assets/imgs/500-bg.jpg) no-repeat top center #143c3d;
  background-size: contain;
  float: left;
  width: 100%;
  height: 100vh;
  text-align: center;
  color: #fff;
}
.errorserver-block h2 {
  font-size: 50px;
  font-family:  sans-serif;
  font-weight: normal;
  margin-top: 20px;
}
.errorserver-block h1 {
  font-size: 100px;
  font-family: sans-serif;
  font-weight: normal;
}

.error-image {
  background: url(./assets/imgs/500-monitor.png) no-repeat top center;
  background-size: cover;
  height: 30vh;
  width: 100%;
  margin: 6vh 0;
}
.errorserver-block p {
  font-size: 24px;
  line-height: 1;
  font-family: sans-serif;
}
.back-button-five a {
  padding: 10px 20px;
  background: #007d66;
  border-radius: 3px;
  color: #fff;
  font-family:  sans-serif;
  font-size: 24px;
  margin-top: 10px;
  display: inline-block;
  min-width: 184px;
  text-decoration: none;
}

/* Loader styling  */

.loader-logo {
  width: 70px;
  height: 70px;
  position: absolute;
  z-index: 100;
  background: #fff url(/src/assets/imgs/loader_all.png) no-repeat center center;
  margin: 3px auto 10px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 100%;
  background-size: 64px;
}

#loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
  background: rgba(255, 255, 255, 0.6);
}

#loader-wrapper p {
  position: absolute;
  width: 120px;
  text-align: center;
  font-size: 18px;
  color: #8646a3;
  top: 85px;
  left: -10px;
}

.loader-inner-wrapper {
  width: 100px;
  text-align: center;
  margin: auto;
  bottom: 0;
  height: 100px;
  top: 0;
  left: 0;
  right: 0;
  position: absolute;
  border-radius: 100%;
  z-index: 1;
}

#loader-left {
  position: absolute;
  top: 50%;
  width: 50px;
  height: 100px;
  margin: -50px 0;
  right: 0;
  overflow: hidden;
  z-index: 1;
}

#loader-left .anim {
  margin: -50px 0 0 -55px;
  left: 17px;
  border-radius: 120px 0 0 120px;
  -webkit-animation: spin-right 3s linear infinite;
  animation: spin-right 3s linear infinite;
  transform-origin: 100% 50% 0;
  z-index: 1;
}

#loader-left .anim,
#loader-right .anim {
  position: absolute;
  top: 50%;
  width: 38px;
  height: 76px;
  background: #512568;
}

#loader-right {
  position: absolute;
  top: 50%;
  width: 50px;
  height: 100px;
  margin: -50px 0 0 -50px;
  left: 50px;
  overflow: hidden;
  z-index: 1;
}

#loader-right .anim {
  margin: -50px 0;
  background: #622e79;
  left: 50px;
  border-radius: 0 120px 120px 0;
  -webkit-animation: spin-left 3s linear infinite;
  animation: spin-left 3s linear infinite;
  transform-origin: 0% 50% 0;
  z-index: 1;
}

@keyframes spin-right {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(180deg);
  }

  50% {
    transform: rotate(180deg);
  }

  75% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes spin-left {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(180deg);
  }

  75% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
